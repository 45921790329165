import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Homeicon from "../images/home";
import LampIcon from "../images/lamp";
import HeartIcon from "../images/heart";
import LogoutIcon from "../images/logout";
import "../style/hidescrollbar.css"
export default function Layout({ children }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    return (
        <>
            <Navbar onToggleSidebar={toggleSidebar} />

            <div className="flex h-screen bg-gray-200">
                <aside
                    className={`fixed inset-y-0 left-0 top-11 w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
                        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                    } lg:translate-x-0 lg:relative lg:w-64 lg:shadow-none lg:top-0 lg:z-0 z-30 flex flex-col`}>
                    <div className="relative mb-2">
                        <button onClick={toggleSidebar} className="lg:hidden absolute top-1 right-4 p-2 text-gray-700">
                            <i className="pi pi-times-circle" style={{ fontSize: '1.5em' }}></i>
                        </button>
                    </div>
                    <nav className="p-4 w-full flex flex-col flex-1 lg:mt-5 ">
                        <ul className="flex-1">
                            <li>
                                <Link to="/public" className="block px-1 text-black">
                                    <button type="button" className="w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] hover:font-bold  rounded-lg hover:border-1 border-blue-100 text-black hover:bg-[#C2EAFF]">
                                        <LampIcon />
                                        Test d'orientation
                                    </button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/metiers" className="block px-1 text-black">
                                    <button type="button" className="w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] hover:font-bold  rounded-lg hover:border-1 border-blue-100 text-black hover:bg-[#C2EAFF]">
                                        <Homeicon />
                                        Explorer
                                    </button>
                                </Link>
                            </li>

                            <li>
                                <Link to="/saveditems" className="block px-1 text-black">
                                    <button type="button" className="w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] hover:font-bold  rounded-lg hover:border-1 border-blue-100 text-black hover:bg-[#C2EAFF]">
                                        <HeartIcon />
                                        Enregistrés
                                    </button>
                                </Link>
                            </li>

                        </ul>
                        <div className="mt-auto">
                            <hr className="border-gray-300" />

                            {/* Logout Button */}
                            <button type="button" className="w-full py-3 px-2 inline-flex items-center gap-x-1 text-md font-inter text-[16px] hover:font-bold rounded-lg hover:border-1 border-blue-100 text-black">
                                <LogoutIcon />
                                Logout
                            </button>
                        </div>
                    </nav>
                </aside>

                <div className={`flex-1 flex flex-col transition-all duration-300 ease-in-out ${
                    isSidebarOpen ? 'lg:ml-64 ml-0' : 'lg:ml-0 ml-0'
                }`}>
                    <main className="flex-1 p-2 bg-gray-100  lg:overflow-x-scroll sm:overflow-x-visible   no-scrollbar">
                        {children}
                    </main>
                </div>
            </div>
        </>
    );
}
