import React, { createContext, useContext, useState } from 'react';

const ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [finalSectionProgress, setFinalSectionProgress] = useState(0);

    return (
        <ProgressContext.Provider value={{ currentPage, setCurrentPage, finalSectionProgress, setFinalSectionProgress }}>
            {children}
        </ProgressContext.Provider>
    );
};

export const useProgress = () => useContext(ProgressContext);
