import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography, Image } from 'antd';
import Envelope from '../../../images/envelope.svg';
import LoginModal from './LoginModal';

const { Title, Paragraph } = Typography;

export function SuccessResetModal({ visible, onHideSuccessReset }) {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                onHideSuccessReset();
                setIsLoginModalVisible(true);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [visible, onHideSuccessReset]);

    const handleCloseLoginModal = () => {
        setIsLoginModalVisible(false); // Handle the closing of login modal
    };

    return (
        <>
            <Modal
                visible={visible}
                onCancel={onHideSuccessReset}
                footer={null}
                centered
                width={400}
                style={{
                    textAlign: 'center',
                }}
            >
                <Button
                    type="link"
                    onClick={onHideSuccessReset}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                >
                    &times;
                </Button>
                <Title level={2} style={{ marginBottom: '16px' }}>
                    Mot de passe modifié avec succès
                </Title>
                <div style={{ padding: '16px' }}>
                    <Paragraph>
                        Votre mot de passe a été réinitialisé avec succès. Vous allez être redirigé vers la page de connexion.
                    </Paragraph>
                    <Image
                        src={Envelope}
                        alt="Icône d'email"
                        preview={false}
                        style={{ height: '80px', width: '80px' }}
                    />
                </div>
            </Modal>

            {/* Show the LoginModal only if it's visible */}
            {isLoginModalVisible && (
                <LoginModal visible={isLoginModalVisible} onHide={handleCloseLoginModal} />
            )}
        </>
    );
}
