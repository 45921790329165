import Graduate from "../../images/formationIcons/graduate.svg";
import Alternance from "../../images/formationIcons/alternance.svg";
import Flag from "../../images/formationIcons/flag.svg";
import Calendar from "../../images/formationIcons/calendar.svg";
import Keys from "../../images/formationIcons/key.svg";
import Group from "../../images/formationIcons/groupe.svg";
import Student from "../../images/formationIcons/student.svg";
import Success from "../../images/formationIcons/successtag.svg";
import React from "react";
import { Tooltip } from 'antd';
import {InfoCircleFilled} from '@ant-design/icons';
export default function PresentationDuCours({niveau,apprentissage,amenagement,duree,taux_dacces,totalcandidatsformation,selectivite,fiche,capacite}){
    const toInteger = (value) => {
        return Math.floor(value);
    };
    return(
        <div>
            {/*<div className="relative  md:absolute  md:w-4/12 md:right-20  md:mr-4  bg-white p-4 mx-2  rounded-lg shadow-lg  md:top-5 -top-8 md:translate-x-1/4  md:mt-0 ">*/}
                <div className="relative md:absolute md:w-4/12 xl:w-4/12 md:right-20 md:mr-4 xl:mr-7 bg-white p-4  mx-2 rounded-lg shadow-lg md:top-5 -top-8 md:translate-x-1/4 md:mt-0">

            <div className="p-2">
                    <p className="font-poppins text-[16px] font-semibold text-[#141943]">Présentation</p>
                    <hr className="border-t-2 border-gray-300 mb-2" />
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <img
                                src={Graduate}
                                alt="Profile"
                                className=" mr-1"
                            />
                            <span className="text-[16px] text-[#141943] ">Niveau d'études</span>
                        </div>
                        <div>
                            <span className="text-[16px] text-[#141943] font-semibold">{niveau}</span>
                        </div>
                    </div>
                    <div className="flex mt-3 justify-between items-center">
                        <div className="flex items-center">
                            <img
                                src={Alternance}
                                alt="Profile"
                                className=" mr-1"
                            />
                            <span className="flex text-[16px] text-[#141943] cursor-pointer ">Alternance
                                {apprentissage === "2" && (
                                    <Tooltip
                                        color="white"
                                        key="black"
                                        title="Alternance sur tout ou une partie du parcours à partir de la deuxième année."
                                        overlayInnerStyle={{ color: "black" }}
                                    >
                                        <span className="ml-2  border-1 rounded-full p-1 ">
                                        <svg width="12" height="12" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.96 0.229999C7.36 0.129999 7.74 0.159999 8.1 0.319999C8.64 0.58 8.88 1.04 8.82 1.7C8.8 1.8 8.78 1.89 8.76 1.97C8.54 2.73 8.04 3.24 7.26 3.5C7.22 3.5 7.18 3.51 7.14 3.53C6.56 3.69 6.07 3.57 5.67 3.17C5.21 2.69 5.16 2.11 5.52 1.43C5.8 0.789999 6.28 0.389999 6.96 0.229999ZM4.29 6.8C4.57 6.74 4.93 6.73 5.37 6.77C6.53 6.99 7.26 7.62 7.56 8.66C7.66 9.08 7.66 9.52 7.56 9.98L6.21 13.64C5.31 16 4.85 17.23 4.83 17.33C4.59 18.11 4.58 18.61 4.8 18.83C4.86 18.87 4.97 18.89 5.13 18.89C5.35 18.89 5.49 18.88 5.55 18.86C6.41 18.56 7.07 17.67 7.53 16.19C7.57 16.11 7.6 16.02 7.62 15.92C7.72 15.62 7.82 15.46 7.92 15.44C7.98 15.42 8.16 15.41 8.46 15.41C8.84 15.41 9.05 15.43 9.09 15.47C9.19 15.55 9.21 15.72 9.15 15.98C8.77 17.3 8.17 18.37 7.35 19.19C6.79 19.71 6.22 20.06 5.64 20.24C5.44 20.28 5.17 20.3 4.83 20.3C4.47 20.3 4.21 20.28 4.05 20.24C3.41 20.08 2.93 19.79 2.61 19.37C2.37 19.09 2.21 18.77 2.13 18.41C2.03 17.97 2.03 17.52 2.13 17.06L3.51 13.37C4.43 10.91 4.92 9.56 4.98 9.32C5.06 8.98 5.06 8.68 4.98 8.42C4.88 8.18 4.66 8.1 4.32 8.18C3.72 8.3 3.18 8.76 2.7 9.56C2.4 10.06 2.16 10.67 1.98 11.39C1.92 11.51 1.88 11.58 1.86 11.6C1.82 11.64 1.61 11.66 1.23 11.66H0.66L0.57 11.57C0.47 11.49 0.48 11.28 0.6 10.94C1.1 9.26 1.91 8.05 3.03 7.31C3.13 7.25 3.24 7.19 3.36 7.13C3.72 6.93 4.03 6.82 4.29 6.8Z" fill="#2E5BAF"/>
                                        </svg>
                                            </span>

                                    </Tooltip>

                                )}
                            </span>
                        </div>
                        <div>
                            <span className="text-[16px] text-[#141943] font-semibold">{["1", "2"].includes(apprentissage) ? "Oui" : "Non"}</span>
                        </div>

                    </div>
                    <div className="flex mt-3 justify-between items-center">
                        <div className="flex items-center">
                            <img
                                src={Flag}
                                alt="Profile"
                                className=" mr-1"
                            />
                            {(amenagement === "En présentiel" || amenagement === "À distance") ? (
                                <span className="text-[16px] text-[#141943]">Formation à distance</span>
                            ) : (
                                <span className="text-[16px] text-[#141943]">Partiellement à distance</span>
                            )}
                        </div>
                        <div>
                            {/*<span className="text-[16px] text-[#141943] font-semibold">Non</span>*/}
                            <span className="text-[16px] text-[#141943] font-semibold">{amenagement === "En présentiel"  ? "Non" : "Oui"}</span>

                        </div>
                    </div>
                    <div className="flex mt-3 justify-between items-center">
                        <div className="flex items-center">
                            <img
                                src={Calendar}
                                alt="Profile"
                                className=" mr-1"
                            />
                            <span className="text-[16px] text-[#141943] ">Durée </span>
                        </div>
                        <div>
                            <span className="text-[16px] text-[#141943] font-semibold">{duree}</span>
                        </div>
                    </div>
                    <div className="flex mt-3 justify-between items-center">
                        <div className="flex items-center">
                            <img
                                src={Keys}
                                alt="Profile"
                                className=" mr-1"
                            />
                            <span className="text-[16px] text-[#141943] ">Taux d'accès</span>
                        </div>
                        <div>
                                <span className="text-[16px] text-[#141943] font-semibold">
                                    {taux_dacces ? toInteger(parseFloat(taux_dacces)) : '--'}{" "}%
                                </span>
                        </div>
                    </div>
                    <div className="flex mt-3 justify-between items-center">
                        <div className="flex items-center">
                            <img
                                src={Group}
                                alt="Group"
                                className=" mr-1"
                            />
                            <span className="text-[16px] text-[#141943] ">Nombre de candidats</span>
                        </div>
                        <div>
                            <span className="text-[16px] text-[#141943] font-semibold">
                                {totalcandidatsformation ? toInteger(parseFloat(totalcandidatsformation)) : '--'}
                            </span>
                        </div>
                    </div>
                    <div className="flex mt-3 justify-between items-center">
                        <div className="flex items-center">
                            <img
                                src={Student}
                                alt="Nombre d'étudiants "
                                className=" mr-1"
                            />
                            <span className="text-[16px] text-[#141943] ">Nombre d'étudiants </span>
                        </div>
                        <div>
                            <span className="text-[16px] text-[#141943] font-semibold">
                             {capacite ? toInteger(parseFloat(capacite)) : '--'}
                            </span>
                        </div>
                    </div>
                    <div className="flex mt-3 justify-between items-center">
                        <div className="flex items-center">
                            <img
                                src={Success}
                                alt="Admission sélective "
                                className=" mr-1"
                            />
                            <span className="text-[16px] text-[#141943] ">Admission sélective</span>
                        </div>
                        <div>
                            <span className="text-[16px] text-[#141943] font-semibold">{selectivite === "formation sélective" ? "Oui" : "Non"}</span>
                        </div>
                    </div>

                    <hr className="border-t-2 my-3 border-gray-300 mb-2" />

                    <a  href={fiche}
                        target="_blank">


                        <button  className=" w-full p-2 bg-blue-500 rounded-md text-white">
                            Voir sur Parcoursup
                        </button>
                    </a>

                </div>
            </div>

</div>
    )
}
