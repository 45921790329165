import React, { useState } from 'react';
import { VerificationModal } from '../modal/auth/VerificationModal';
import { useAuth } from '../../services/auth.provider';
import { useAuthService } from '../../services/auth.service';
import ConditionsGeneralesDialog from "../../utils/ConditionsGenerales";
import { Input, Button, Tooltip, Typography, Alert } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, GoogleOutlined } from '@ant-design/icons';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { CryptoService } from '../../services/crypte.service';
import { useSubmissionService } from '../../services/submission.service';

const { Title, Text } = Typography;

export default function SignUpStep({ onSubmit }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showOtpModal, setShowOtpModal] = useState(false);
    const { signUp } = useAuth();
    const [alreadyExist, setAlreadyExist] = useState(false);
    const [emptyValue, setEmptyValue] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);
    const { checkEmail } = useAuthService();
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const { userSubmission } = useSubmissionService();

    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const [passwordValidation, setPasswordValidation] = useState({
        minLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
        hasSpecialChar: false
    });
    const [showTooltip, setShowTooltip] = useState(false);
    const validatePassword = (password) => {
        const validation = {
            minLength: password.length >= 8,
            hasUppercase: /[A-Z]/.test(password),
            hasLowercase: /[a-z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[@$!%*?&]/.test(password),
        };
        setPasswordValidation(validation);
        return validation;
    };
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        const validation = validatePassword(value);
        const isValid = Object.values(validation).every(Boolean);
        setShowTooltip(!isValid);
        //setIsPasswordMatch(value === confirmPassword);
    };

    const getValidationMessage = () => (
        <div>
            <Text type={passwordValidation.minLength ? 'success' : 'danger'}>Au moins 8 caractères</Text><br />
            <Text type={passwordValidation.hasUppercase ? 'success' : 'danger'}>Une majuscule</Text><br />
            <Text type={passwordValidation.hasLowercase ? 'success' : 'danger'}>Une minuscule</Text><br />
            <Text type={passwordValidation.hasNumber ? 'success' : 'danger'}>Un chiffre</Text><br />
            <Text type={passwordValidation.hasSpecialChar ? 'success' : 'danger'}>Un caractère spécial (@$!%*?&)</Text>
        </div>
    );

    // const handleConfirmPasswordChange = (e) => {
    //     const value = e.target.value;
    //     setConfirmPassword(value);
    //     setIsPasswordMatch(e.target.value === password);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setAlreadyExist(false);
        setEmptyValue(false);

        if (!email || !password) {
            setIsLoading(false);
            setEmptyValue(true);
            return;
        }
        // Validate password complexity
        const isValidPassword = Object.values(validatePassword(password)).every(Boolean);
        if (!isValidPassword) {
            setIsLoading(false);
            alert("Le mot de passe doit comporter au moins 8 caractères, une majuscule, une minuscule, un chiffre, et un caractère spécial.");
            return;
        }

        // if (password !== confirmPassword) {
        //     setIsLoading(false);
        //
        //     alert("Les mots de passe ne correspondent pas.");
        //     return;
        // }
        try {
            let lastEmail = localStorage.getItem('email-signup');
            let response;

            if (lastEmail) {
                response = await checkEmail(email, lastEmail, 1);
            } else {
                response = await checkEmail(email, null, 0);
            }

            if (response.data.status === 'success') {
                setShowOtpModal(true);
                setAlreadyExist(false);
                setEmptyValue(false);
            } else {
                setAlreadyExist(true);
                console.error(response.data.message, 'An error occurred');
            }
        } catch (error) {
            setAlreadyExist(true);
            console.error('Signup failed:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const googleLoginHandler = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${response.access_token}`,
                    },
                });

                const userInfo = await userInfoResponse.json();
                console.log(userInfo);

                const email = userInfo.email;
                const sub = userInfo.sub;
                console.log('User email:', email);
                const signUpResponse = await signUp(email, null, sub);
                console.log("signUpResponse.data.status ", signUpResponse.data.status)
                if (signUpResponse.data.status === 'success') {

                    const questionsScoresData = JSON.parse(localStorage.getItem('questionsScores')) || {};
                    const subjectsLevelData = JSON.parse(localStorage.getItem('ratings')) || {};
    
                    setTimeout(() => {
                        if (Object.keys(questionsScoresData).length > 0 && Object.keys(subjectsLevelData).length > 0) {
                            navigate("/guidance");
                        } else {
                            navigate("/formation");
                        }
                    }, 2000);
    
                    if (Object.keys(questionsScoresData).length > 0 && Object.keys(subjectsLevelData).length > 0) {
                        const userId = localStorage.getItem('user_id');
                        if (userId) {
                            const decryptedId = CryptoService.decryptData(userId)
                            userSubmission(decryptedId);
                            localStorage.removeItem('questionsScores');
                            localStorage.removeItem('ratings');
                        } else {
                            console.warn("No user ID found in local storage");
                        }
                    }
                    
                    localStorage.removeItem('stepData');
                } else {
                    console.error("Error during sign-up");
                }

            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        },
        onError: () => {
            console.error('Google login failed');
        },
        scope: 'openid email profile',
    });

    const handleGoogleFailure = (error) => {
        console.error(error);
    };

    const isSubmitDisabled = !Object.values(passwordValidation).every(Boolean) || !email ;


    return (
        <div className="flex justify-center items-center mt-2 px-4 sm:px-0">
            <div className="max-w-md w-full bg-white p-4">
                <Title level={1} className="text-center text-[#141943] mb-1" style={{ fontSize: '28px' }}>
                    Dernière étape
                </Title>
                <Text className="text-center text-[#434769] mb-3" style={{ fontSize: '16px' }}>
                    Votre avenir commence ici !
                </Text>
                <div className="space-y-4 mt-4">
                    {emptyValue && (
                        <Alert
                            message="Veuillez remplir tous les champs"
                            type="error"
                            showIcon
                            className="mb-2"
                        />
                    )}

                    {alreadyExist && (
                        <Alert
                            message="L'adresse e-mail que vous avez saisie existe déjà. Veuillez en utiliser une autre."
                            type="error"
                            showIcon
                            className="mb-2"
                        />
                    )}

                    <div className="flex flex-col">
                        <Text className="block mb-1" style={{ fontSize: '12px', color: '#141943E5', fontFamily: 'Inter' }}>
                            E-mail
                        </Text>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="E-mail"
                            className="bg-[#F2F2F2]"
                        />
                    </div>

                    <div className="flex flex-col">
                        <Text className="block mb-1" style={{ fontSize: '12px', color: '#141943E5', fontFamily: 'Inter' }}>
                            Mot de passe
                        </Text>
                        <Tooltip
                            title={getValidationMessage()}
                            placement="bottom"
                            visible={showTooltip}
                            overlayStyle={{ maxWidth: 200 }}
                        >
                            <Input.Password
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="********"
                                iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                className="bg-[#F2F2F2]"
                                style={{ paddingRight: '1rem' }}
                                onFocus={() => setShowTooltip(true)}
                                onBlur={() => setShowTooltip(!Object.values(passwordValidation).every(Boolean))}
                            />
                        </Tooltip>
                    </div>
                    {/*<div className="flex flex-col">*/}
                    {/*    <Text className="block mb-1" style={{ fontSize: '12px', color: '#141943E5', fontFamily: 'Inter' }}>*/}
                    {/*        Confirmer le mot de passe*/}
                    {/*    </Text>*/}
                    {/*    <Input.Password*/}
                    {/*        value={confirmPassword}*/}
                    {/*        onChange={handleConfirmPasswordChange}*/}
                    {/*        placeholder="********"*/}
                    {/*        iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}*/}
                    {/*        className={`bg-[#F2F2F2] ${confirmPassword !== password ? 'border-red-500' : ''}`}*/}
                    {/*        style={{ paddingRight: '1rem' }}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="text-center mt-3 mb-1">
                        <Text style={{ fontSize: '12px', color: '#434769' }}>
                            En cliquant sur S'inscrire ou sur Se connecter, vous acceptez les <a onClick={() => setOpenConditionsDialog(true)} className="text-blue-500 cursor-pointer">CGU</a> de Linkyways.
                        </Text>
                    </div>

                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        className="w-full mt-2"
                        loading={isLoading}
                        disabled={isSubmitDisabled}

                    >
                        {isLoading ? 'Chargement...' : 'S\'inscrire'}
                    </Button>

                    <div className="flex items-center my-3">
                        <div className="flex-grow border-t border-gray-300"></div>
                        <span className="mx-4 text-gray-400 font-bold text-sm">ou</span>
                        <div className="flex-grow border-t border-gray-300"></div>
                    </div>

                    <div className="space-y-2 flex flex-col items-center">
                        <Button
                            icon={<GoogleOutlined />}
                            block
                            size="large"
                            onClick={() => googleLoginHandler()}
                        >
                            Continuer avec Gmail
                        </Button>
                        <VerificationModal
                            email={email}
                            password={password}
                            visible={showOtpModal}
                            onHide={() => setShowOtpModal(false)}
                        />
                        <ConditionsGeneralesDialog
                            open={openConditionsDialog}
                            onClose={() => setOpenConditionsDialog(false)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

