import React, { useState, useRef, useEffect } from "react";
import dropdownSvg from "../../images/dropDownMobile.svg";
import "../../style/dropdown-mobile.css";

const DropDownMobile = ({ options, onChange }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownButtonRef = useRef(null);
    const dropdownMenuRef = useRef(null);

    // Add "All" at the beginning of options
    const allOptions = [...options];

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const handleOptionClick = (option) => {
        setSelectedOptions((prevSelected) => {
          const isSelected = prevSelected.some((o) => o.cat_id === option.cat_id);
          let newSelected = [];
    
          if (isSelected) {
            newSelected = prevSelected.filter((o) => o.cat_id !== option.cat_id);
          } else {
            newSelected = [...prevSelected, { cat_id: option.cat_id, cat_name: option.cat_name }];
          }
    
          // Add logging to check selected options
          // console.log("Selected Options after click:", newSelected);
    
          if (onChange) onChange(newSelected);
          return newSelected;
        });
      };

      const handleClickOutside = (event) => {
        if (
          dropdownButtonRef.current &&
          !dropdownButtonRef.current.contains(event.target) &&
          dropdownMenuRef.current &&
          !dropdownMenuRef.current.contains(event.target)
        ) {
          setIsDropdownOpen(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, []);
    
      const handleClearAll = () => {
        setSelectedOptions([]);
        if (onChange) onChange([]);
        setIsDropdownOpen(false);
      };
    

    const customFooter = (
        <div className="flex justify-between p-1 border-t border-gray-200">
            <button
                onClick={handleClearAll}
                className="font-inter text-gray-600 ml-2 hover:text-blue-600"
            >
                Tout effacer
            </button>
            {/* <button
                onClick={() => setIsDropdownOpen(false)}
                className="font-inter text-black px-2 py-1 border-1 mr-2 border-black rounded-lg hover:text-blue-800"
            >
                Appliquer
            </button> */}
        </div>
    );

    return (
        <>
            <img
                src={dropdownSvg}
                alt="Dropdown"
                className="cursor-pointer "
                onClick={toggleDropdown}
                ref={dropdownButtonRef}
            />
            {isDropdownOpen && (
                <div
                    ref={dropdownMenuRef}
                    className="absolute right-0 w-[280px] mt-2 bg-white border border-gray-300 rounded-lg shadow-lg"
                >
                    <ul className="max-h-60 overflow-y-auto py-2">
                        {allOptions.map((option) => (
                            <li
                                key={option.cat_id}
                                className={`flex items-center p-2 hover:bg-gray-100 ${
                                    selectedOptions.includes(option.cat_id)
                                        ? "bg-blue-50"
                                        : ""
                                } custom-checkbox`}
                            >
                                <input
                                    type="checkbox"
                                    checked={selectedOptions.some((o) => o.cat_id === option.cat_id)}
                                    onChange={() => handleOptionClick(option)}
                                    className="form-checkbox rounded mr-2"
                                />
                                <span className="text-sm text-gray-800">
                                    {option.cat_name}
                                </span>
                            </li>
                        ))}
                    </ul>
                    {customFooter}
                </div>
            )}
        </>
    );
};

export default DropDownMobile;
