import axios from 'axios';
import { CryptoService } from './crypte.service';
import { encryptWithPublicKey } from './rsa.service';

export const TokenService = {

    async loginPublicToken() {
       const encryptedPassword = encryptWithPublicKey('dev@2024#Test');
        
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/login`, {
            user_email: 'dev@linkyways.com',
            user_password: encryptedPassword,
        });

        const { access_token, refresh_token } = response.data.data;
        TokenService.setTokens(access_token, refresh_token);

        return access_token;
    },

    getAccessToken() {
        let token =  CryptoService.decryptData(localStorage.getItem('access_token')) || sessionStorage.getItem('access_token');
        return token;
    },

    setTokens(accessToken, refreshToken) {
        sessionStorage.setItem('access_token', accessToken);
        sessionStorage.setItem('refresh_token', refreshToken);
    },

    clearTokens() {
        localStorage.clear();
        sessionStorage.clear();
    },

    async refreshAccessToken() {
        const refreshToken = sessionStorage.getItem('refresh_token') || localStorage.getItem('refresh_token');
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/refresh`, {
            refresh_token: refreshToken,
        });

        const { access_token } = response.data.data;
        sessionStorage.setItem('access_token', access_token);

        return access_token;
    },
};
