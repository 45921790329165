import React from 'react';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        padding: '8px 12px', // Adjust padding to ensure content fits nicely
        borderRadius: '4px', // Rounded corners
        position: 'relative', // Ensure relative positioning for the arrow
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', // Tooltip shadow

    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#f5f5f9',
        boxShadow: '0px rgba(0, 0, 0, 0.16)', // Tooltip shadow

    },
}));

export default HtmlTooltip;
