import { CryptoService } from "./crypte.service";

const LOCAL_STORAGE_KEY = 'stepData';
const LOCAL_STORAGE_EXPIRY_KEY = 'storageExpiry';

const LOCAL_STORAGE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

/**
 * Saves data to localStorage with an expiry time.
 * @param {Object} data - The data to be saved.
 */
const saveData = (data) => {
    try {
        const expiryTime = Date.now() + LOCAL_STORAGE_EXPIRY_TIME;
        const storageData = { data, expiryTime };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storageData));
    } catch (error) {
        console.error('Failed to save data to localStorage:', error);
    }
};

/**
 * Retrieves data from localStorage if it has not expired.
 * @returns {Object|null} - The data retrieved or null if not available or expired.
 */
const getData = () => {
    try {
        const storageData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        if (storageData) {
            const { data, expiryTime } = storageData;
            if (Date.now() < expiryTime) {
                return data;
            } else {
                // Data has expired, remove it
                localStorage.removeItem(LOCAL_STORAGE_KEY);
            }
        }
        return null;
    } catch (error) {
        console.error('Failed to get data from localStorage:', error);
        return null;
    }
};

/**
 * Clears data from localStorage.
 */
const clearData = () => {
    try {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (error) {
        console.error('Failed to clear data from localStorage:', error);
    }
};

const clearLocalStorage = () => {
    localStorage.clear();
};

const saveAccessToken = (token) => {
    localStorage.setItem('access_token', CryptoService.encryptData(token.toString()));
};

const removeSubGoogle = () => {
    localStorage.removeItem('sub');
};

const saveRefreshToken = (token) => {
    localStorage.setItem('refresh_token', CryptoService.encryptData(token.toString()));
};

const getToken = () => {
    return localStorage.getItem('access_token');
};

const getEmail = () => {
    let userEmail = localStorage.getItem('user_email');
    return CryptoService.decryptData(userEmail);
};

const saveSubGoogle = (sub) => {
    try {
        const encryptedSub = CryptoService.encryptData(sub);
        localStorage.setItem('sub', encryptedSub);
        console.log('Encrypted sub saved:', encryptedSub);
    } catch (error) {
        console.error('Error saving encrypted sub:', error);
    }
};

const getSub = () => {
    try {
        const encryptedSub = localStorage.getItem('sub');
        console.log('Encrypted sub retrieved:', encryptedSub);

        if (!encryptedSub) {
            console.warn('No encrypted sub found in local storage');
            return null;
        }

        const decryptedSub = CryptoService.decryptData(encryptedSub);
        console.log('Decrypted sub:', decryptedSub);
        return decryptedSub;
    } catch (error) {
        console.error('Error retrieving or decrypting sub:', error);
        return null;
    }
};


const isLoggedIn = () => {
    return !!getToken();
};

const clearToken = () => {
    localStorage.removeItem('access_token');
};

const logout = () => {
    localStorage.clear();
};

const isMoteur = () => {
    return !!localStorage.getItem('questionsScores');
};

export const LocalStorageService = {
    saveData,
    getData,
    clearData,
    saveAccessToken,
    saveRefreshToken,
    getToken,
    isLoggedIn,
    clearToken,
    clearLocalStorage,
    saveSubGoogle,
    removeSubGoogle,
    getEmail,
    getSub,
    isMoteur,
    logout
};
