import { useAxios } from './caller.service';

export const useSubmissionService = () => {
    const AxiosInstance = useAxios();

    let userSubmission = async (userId) => {
        try {
            const questionsScoresData = JSON.parse(localStorage.getItem('questionsScores')) || {};
            const subjectsLevelData = JSON.parse(localStorage.getItem('ratings')) || {};
            if (questionsScoresData && subjectsLevelData) {
                const questionsScores = Object.entries(questionsScoresData).map(([questionId, score]) => ({
                    question_id: parseInt(questionId, 10),
                    score: parseInt(score, 10),
                }));

                const subjectsLevel = Object.entries(subjectsLevelData).map(([subjectId, subjectLevel]) => ({
                    subject_id: parseInt(subjectId, 10),
                    subject_level: parseInt(subjectLevel, 10),
                }));

                if (!questionsScores.every(({ question_id, score }) => Number.isInteger(question_id) && Number.isInteger(score)) ||
                    !subjectsLevel.every(({ subject_id, subject_level }) => Number.isInteger(subject_id) && Number.isInteger(subject_level))) {
                    throw new Error('Invalid data format in localStorage.');
                }

                // Build the request body
                const requestBody = {
                    user_id: userId,
                    questions_scores: questionsScores,
                    subjects_level: subjectsLevel
                };
                // console.log("requestBody ", requestBody);

                const response = await AxiosInstance.post('/UserSubmission/', requestBody);

                // Clear local storage after successful submission
                localStorage.removeItem('selectedCards');
                localStorage.removeItem('selectedCategories');
                localStorage.removeItem('questionsScores');
                localStorage.removeItem('ratings');

                return response.data;
            }

        } catch (error) {
            console.error('Error submitting data:', error);
            throw error;
        }
    };

    let getUserSubmissionData = (user_id) => {
        if (user_id == null) {
            return;
        }
        return AxiosInstance.get(`/GetUserSubmissionData/${user_id}`);
    }

    return {
        userSubmission,
        getUserSubmissionData
    }
}
