
import React, { useState, useEffect } from "react";
import { AutoComplete, Input, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import locationsData from '../location.json';
import { useAuth } from "../services/auth.provider";

export default function LocationSelector({ onLocationSelect , selectedLocations }) {
    const [selectedItems, setSelectedItems] = useState(selectedLocations || []); // Initialize with selectedLocations
    const [inputValue, setInputValue] = useState("");
    const [locations, setLocations] = useState([]);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        // Ensure selectedLocations is an array before setting it
        if (selectedLocations && Array.isArray(selectedLocations)) {
            setSelectedItems(selectedLocations);
        } else {
            setSelectedItems([]); // Default to an empty array if selectedLocations is undefined or not an array
        }
    }, [selectedLocations]);

    useEffect(() => {
        setLocations(locationsData.map(loc => loc.Localisation));
    }, []);


    const handleSelect = (value) => {
        if (!selectedItems.includes(value) && selectedItems.length < 3) {
            const newSelectedItems = [...selectedItems, value];
            setSelectedItems(newSelectedItems);
            onLocationSelect(newSelectedItems);
        }
        setInputValue("");
    };

    const handleClear = () => {
        setInputValue('');
        setSelectedItems([]);
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const filteredOptions = isAuthenticated
        ? locations
            .filter((loc) =>
                loc.toLowerCase().includes(inputValue.toLowerCase()) && !selectedItems.includes(loc)
            )
            .map((loc) => ({ value: loc }))
        : [];

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && filteredOptions.length > 0) {
            handleSelect(filteredOptions[0].value);
            e.preventDefault();
        }
    };

    return (
        <AutoComplete
            className="custom-autocomplete w-full mb-1"
            dropdownMatchSelectWidth={true}
            options={filteredOptions}
            onSelect={handleSelect}
            onChange={(value) => setInputValue(value)}
        >
            <div
                className="scrollable-input mt-1"
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    overflowX: "auto",
                    border: "none",
                    borderRadius: "0px",
                    position: "relative",
                }}
            >
                <span className="location-icon ml-2">
                    {/* Location Icon */}
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.18359 10.0625C10.4262 10.0625 11.4336 9.05514 11.4336 7.8125C11.4336 6.56986 10.4262 5.5625 9.18359 5.5625C7.94095 5.5625 6.93359 6.56986 6.93359 7.8125C6.93359 9.05514 7.94095 10.0625 9.18359 10.0625Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.8086 7.8125C14.8086 12.875 9.18359 16.8125 9.18359 16.8125C9.18359 16.8125 3.55859 12.875 3.55859 7.8125C3.55859 6.32066 4.15123 4.88992 5.20612 3.83502C6.26101 2.78013 7.69175 2.1875 9.18359 2.1875C10.6754 2.1875 12.1062 2.78013 13.1611 3.83502C14.216 4.88992 14.8086 6.32066 14.8086 7.8125V7.8125Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>

                {selectedItems.map((item) => (
                    <Tag
                        closable
                        onClose={() => handleClear(item)}
                        key={item}
                        style={{
                            marginRight: 2,
                            marginBottom: 0,
                            padding: "0 2px",
                            lineHeight: "20px"
                        }}
                    >
                        {truncateText(item, 5)}
                    </Tag>
                ))}

                <Input
                    className="bg-transparent"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                        flex: 1,
                        maxWidth: "100%",
                        minWidth: "10px",
                    }}
                    // placeholder={selectedItems.length === 0 ? "Emplacement..." : ""}
                    placeholder={selectedItems.length === 0 && inputValue === '' ? "Emplacement..." : ""}

                    suffix={
                        <CloseOutlined
                            onClick={() => handleClear()}
                            style={{
                                cursor: "pointer",
                                display: selectedItems.length ? "inline-block" : "none",
                            }}
                        />
                    }
                />
            </div>
        </AutoComplete>
    );
}


// import React, { useState, useEffect } from "react";
// import { AutoComplete, Input, Tag } from "antd";
// import { CloseOutlined } from "@ant-design/icons";
// import locationsData from '../location.json';
// import {useAuth} from "../services/auth.provider";
//
// export default function LocationSelector({ onLocationSelect }) {
//     const [selectedItems, setSelectedItems] = useState([]);
//     const [inputValue, setInputValue] = useState("");
//     const [locations, setLocations] = useState([]);
//     const { isAuthenticated } = useAuth();
//
//     useEffect(() => {
//         setLocations(locationsData.map(loc => loc.Localisation));
//     }, []);
//
//     const handleSelect = (value) => {
//         if (!selectedItems.includes(value) && selectedItems.length < 3) {
//             const newSelectedItems = [...selectedItems, value];
//             setSelectedItems(newSelectedItems);
//             onLocationSelect(newSelectedItems); // Pass selected locations to the parent
//         }
//         setInputValue("");
//     };
//
//     const handleClear = (removedItem) => {
//         const newSelectedItems = removedItem ?
//             selectedItems.filter((item) => item !== removedItem) : [];
//         setSelectedItems(newSelectedItems);
//         onLocationSelect(newSelectedItems);
//     };
//
//
//     const truncateText = (text, maxLength) => {
//         return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
//     };
//
//     const filteredOptions = isAuthenticated
//         ? locations
//             .filter((loc) =>
//                 loc.toLowerCase().includes(inputValue.toLowerCase()) && !selectedItems.includes(loc)
//             )
//             .map((loc) => ({ value: loc }))
//         : [];
//
//     return (
//         <AutoComplete
//             className="custom-autocomplete w-full"
//             dropdownMatchSelectWidth={true}
//             options={filteredOptions}
//             onSelect={handleSelect}
//             onChange={(value) => setInputValue(value)}
//         >
//             <div
//                 className="scrollable-input mt-1"
//                 style={{
//                     display: "flex",
//                     alignItems: "center",
//                     flexWrap: "wrap",
//                     overflowX: "auto",
//                     border: "none",
//                     borderRadius: "0px",
//                     position: "relative",
//                 }}
//             >
//                 <span className="location-icon ml-2">
//                     {/* Location Icon */}
//                     <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M9.18359 10.0625C10.4262 10.0625 11.4336 9.05514 11.4336 7.8125C11.4336 6.56986 10.4262 5.5625 9.18359 5.5625C7.94095 5.5625 6.93359 6.56986 6.93359 7.8125C6.93359 9.05514 7.94095 10.0625 9.18359 10.0625Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//                         <path d="M14.8086 7.8125C14.8086 12.875 9.18359 16.8125 9.18359 16.8125C9.18359 16.8125 3.55859 12.875 3.55859 7.8125C3.55859 6.32066 4.15123 4.88992 5.20612 3.83502C6.26101 2.78013 7.69175 2.1875 9.18359 2.1875C10.6754 2.1875 12.1062 2.78013 13.1611 3.83502C14.216 4.88992 14.8086 6.32066 14.8086 7.8125V7.8125Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//                     </svg>
//                 </span>
//
//                 {/* Render the selected tags */}
//                 {selectedItems.map((item) => (
//                     <Tag
//                         closable
//                         onClose={() => handleClear(item)}
//                         key={item}
//                         style={{
//                             marginRight: 2,
//                             marginBottom: 0,
//                             padding: "0 2px",
//                             lineHeight: "20px"
//                         }}
//                     >
//                         {/*{item}*/}
//                         {truncateText(item, 5)}
//
//                     </Tag>
//                 ))}
//
//                 {/* Render the input field */}
//                 <Input
//                     className="bg-transparent"
//                     value={inputValue}
//                     onChange={(e) => setInputValue(e.target.value)}
//                     style={{
//                         border: "none",
//                         outline: "none",
//                         boxShadow: "none",
//                         flex: 1,
//                         maxWidth: "100%",
//                         minWidth: "10px",
//                     }}
//                     placeholder={selectedItems.length === 0 ? "Emplacement..." : ""}
//                     suffix={
//                         <CloseOutlined
//                             // onClick={() => setSelectedItems([])}
//                             onClick={() => handleClear()}
//                             style={{
//                                 cursor: "pointer",
//                                 display: selectedItems.length ? "inline-block" : "none",
//                             }}
//                         />
//                     }
//                 />
//             </div>
//         </AutoComplete>
//     );
// }