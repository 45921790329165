import React, { useState } from 'react';
import { Avatar, Button, Menu, Dropdown } from 'antd';
import {LogoutOutlined, MenuOutlined} from '@ant-design/icons'; // Import UpOutlined for the "up" arrow
import logo from "../images/logo.svg";
import DefaultUser from "../images/user.svg";
import { Link } from 'react-router-dom';
import { useAuth } from "../services/auth.provider";
import { CryptoService } from '../services/crypte.service';

export default function Navbar({ onToggleSidebar }) {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { isAuthenticated, logout } = useAuth();

    const userSurname = CryptoService.decryptData(localStorage.getItem('user_surname'));
    const userNameInitial = CryptoService.decryptData(localStorage.getItem('user_name'))?.charAt(0).toUpperCase();
    const userEmail = CryptoService.decryptData(localStorage.getItem('user_email'));

    const menu = (
        <Menu>

            <Menu.Item key="email" disabled>
                <span className="text-gray-400 font-poppins font-semibold">{userNameInitial} {userSurname}</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="email" disabled>
                <span className="text-gray-400 font-poppins font-semibold">{userEmail}</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" onClick={logout}>
                <LogoutOutlined rotate={180}  />
                <span className="text-gray-800 font-poppins font-semibold">Déconnexion</span>
            </Menu.Item>
        </Menu>
    );

    const handleDropdownVisibleChange = (flag) => {
        setDropdownVisible(flag);
    };

    return (
        <nav className={`bg-white p-1 ${!isAuthenticated ? 'py-2' : ''} flex justify-between items-center fixed top-0 left-0 w-full border-b border-gray-300 z-50`}>
            <div className="flex items-center">
                {/* Logo */}
                <Link to="/landing">
                    <img
                        alt="logo"
                        src={logo}
                        width="170"
                        className="pl-3 cursor-pointer"
                    />
                </Link>
            </div>

            {isAuthenticated && (
                <div className="flex items-center space-x-2 relative">
                    <div className="hidden lg:flex items-center p-2 space-x-2">
                        <Dropdown
                            overlay={menu}
                            trigger={['click']}
                            placement="bottomRight"
                            onVisibleChange={handleDropdownVisibleChange} // Track dropdown visibility
                        >
                            <div className="flex items-center cursor-pointer">
                                <Button
                                    icon={<MenuOutlined />}
                                    className="ml-2"
                                />
                            </div>
                        </Dropdown>
                    </div>

                    {/* For mobile */}
                    <div className="lg:hidden relative mt-1 flex mr-2">
                        {isAuthenticated && (
                            <Button
                                icon={<MenuOutlined />}
                                className="lg:hidden ml-2"
                                onClick={onToggleSidebar}
                            />
                        )}
                    </div>
                </div>
            )}

        </nav>
    );
}
