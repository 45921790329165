import React from "react";
import UnCheckedIcon from "../../images/search/unchecked";
import CheckedIcon from "../../images/search/checked";

export default function CustomCard({ title, description, image, checked, onClick }) {
    return (
        <div
            className={`relative border rounded-lg pt-3 pr-3 pl-3 pb-1 shadow-md cursor-pointer transition-colors duration-200 w-full ${checked ? 'bg-[#FAF2D3] border-black' : 'bg-white border-[#D0DBE7]'}`}
            onClick={onClick}
        >
            <div className="flex justify-between items-start">
                <img
                    src={image}
                    alt={title}
                    className="h-14  object-fit"
                />
                <div className="item-end p-1">
                    {checked ? (
                        <CheckedIcon/>
                    ) : (
                        <UnCheckedIcon/>
                        )}
                </div>
            </div>
            {/* Title and Description */}
            <div className="mt-3">
                <h3 className="font-poppins text-base text-gray-800">{title}</h3>
                <p className="font-inter text-sm text-gray-500 mt-2">{description}</p>
            </div>
        </div>
    );
}

// import React from "react";
// import { Card } from 'primereact/card';
// import { Checkbox } from 'primereact/checkbox';

// export default function CustomCard({ title, description, image, checked, onClick }) {
//     return (
//         <Card
//             className={`relative p-1 shadow-md border border-gray-300 rounded-md cursor-pointer transition-colors duration-200 max-w-lg ${checked ? 'bg-blue-50 border-blue-400' : 'bg-white border-gray-200'
//                 }`}
//             onClick={onClick}
//             header={
//                 <div className="flex justify-between items-start">
//                     <img
//                         src={image}
//                         alt={title}
//                         className="p-1 h-10 w-4 rounded-lg"
//                     />
//                     <Checkbox
//                         checked={checked}
//                         onChange={() => {}}
//                         className={`p-checkbox-circle p-component absolute top-4 right-4 border border-black ${checked ? 'border-black' : 'border-gray-400'} `}
//                     />
//                 </div>
//             }
//         >
//             <div className="mt-1">
//                 <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
//                 <p className="text-sm text-gray-500 mt-1">{description}</p>
//             </div>
//         </Card>
//     );
// }
