const SecondSideIcon =()=>{
    return(
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M29.5 59C45.7924 59 59 45.7924 59 29.5C59 13.2076 45.7924 0 29.5 0C13.2076 0 0 13.2076 0 29.5C0 45.7924 13.2076 59 29.5 59Z" fill="#F7F8FA"/>
            <path d="M31.4615 34.5H26.5782C26.3117 32.53 24.5003 31 22.3077 31C19.9325 31 18 32.7945 18 35C18 37.2055 19.9325 39 22.3077 39C24.5003 39 26.3117 37.47 26.5782 35.5H31.4615C31.7593 35.5 32 35.2765 32 35C32 34.7235 31.7593 34.5 31.4615 34.5Z" fill="#0F53FA"/>
            <path d="M37.7908 30.9887C37.16 30.9887 36.5638 31.1249 36.0261 31.3627L33.972 28.2353C33.8184 28.001 33.4938 27.9294 33.247 28.0766C33.0008 28.2228 32.9261 28.5317 33.0802 28.7665L35.1332 31.892C34.1872 32.627 33.5816 33.7441 33.5816 34.9943C33.5816 37.203 35.47 39 37.7908 39C40.1117 39 42 37.203 42 34.9943C42 32.7857 40.1117 30.9887 37.7908 30.9887Z" fill="#0F53FA"/>
            <path d="M29.7648 19C27.4296 19 25.5295 20.811 25.5295 23.0369C25.5295 24.2969 26.1389 25.4227 27.0902 26.1634L25.0806 29.2279C24.9255 29.4646 25.0012 29.776 25.2484 29.9233C25.3358 29.9758 25.4332 30 25.5285 30C25.7048 30 25.8773 29.9162 25.9779 29.7628L27.9891 26.6963C28.5302 26.9365 29.13 27.0738 29.7648 27.0738C32.1 27.0738 34 25.2627 34 23.0369C34 20.811 32.1 19 29.7648 19Z" fill="#0F53FA"/>
        </svg>



    )
}
export default SecondSideIcon;