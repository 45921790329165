import React from 'react';
import { createContext, useContext, useState } from 'react';
import { useAxios } from './caller.service';
import { useNavigate } from 'react-router-dom';
import { CryptoService } from './crypte.service';
import { LocalStorageService } from './localStorage.service';

const AuthContext = createContext();


export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('access_token'));
    const navigate = useNavigate();
    const AxiosInstance = useAxios();

    const login = async (email, password) => {
        const data = {
            user_email: email,
            user_password: password,
        };

        try {
            const response = await AxiosInstance.post('/user/login', data);

            if (response.data.status === "success") {
                const { user_id, user_name, user_surname, user_email, access_token } = response.data.data;
                // console.log(user_id)
                localStorage.setItem('user_id', CryptoService.encryptData(user_id.toString()));
                localStorage.setItem('user_name', CryptoService.encryptData(user_name.toString()));
                localStorage.setItem('user_surname', CryptoService.encryptData(user_surname.toString()));
                localStorage.setItem('user_email', CryptoService.encryptData(user_email.toString()));
                localStorage.setItem('access_token', CryptoService.encryptData(access_token.toString()));

                setIsAuthenticated(true);
                return response.data;
            } else {
                setIsAuthenticated(false);
                console.error("Unexpected API response structure", response);
            }
            return response.data;
        } catch (error) {
            setIsAuthenticated(false);
            console.error('Login failed:', error);
            throw error;
        }
    };



    let signUp = async (email, password, sub) => {
        let response;
        const storedData = JSON.parse(localStorage.getItem('stepData'));
        const userDetails = storedData?.data[6] || {};
        // console.log(userDetails , " userDetails");
        let areaStudId;
        let bacType = storedData?.data[1] == 1 ? storedData?.data[3] : 0;
        let bacSpeGenerals = bacType == 1 ? storedData?.data[4] : [];
        let bacSpeTechno = bacType == 2 ? storedData?.data[4] : null;
        let bacSpePro = bacType == 3 ? storedData?.data[4] : null;
        if (storedData?.data[1] == 2 || storedData?.data[1] == 3 || storedData?.data[1] == 5) {
            areaStudId = storedData?.data[3]
        }
        // Format the data for the API
        const formattedData = {
            user_name: userDetails.user_name || null,
            user_surname: userDetails.user_surname || null,
            user_year_of_birth: parseInt(userDetails.user_year_of_birth) || null,
            user_email: email,
            user_password: password,
            id_provider_auth: sub,
            phone_number: userDetails.phone_number || null,
            code_postal: userDetails.postalCode || null,
            country: userDetails.country || null,
            bac_type: bacType || 0,
            studlevel_id: storedData?.data[2] || null,
            status_id: storedData?.data[1] || null,
            area_stud_id: areaStudId || [],
            bacSpeGenerals: bacSpeGenerals || [],
            bac_spe_techno: bacSpeTechno || null,
            bac_spe_pro: bacSpePro || null,
        };

        let data = {
            user_name: formattedData.user_name || null,
            user_surname: formattedData.user_surname || null,
            user_year_of_birth: formattedData.user_year_of_birth || null,
            user_email: formattedData.user_email || null,
            user_password: formattedData.user_password || null,
            id_provider_auth: formattedData.id_provider_auth || null,
            phone_number: formattedData.phone_number || '0',
            code_postal: formattedData.code_postal || '0',
            country: formattedData.country || null,
            bac_type: formattedData.bac_type || 0,
            studlevel_id: formattedData.studlevel_id || null,
            status_id: formattedData.status_id || null,
            area_stud_id: formattedData.area_stud_id || null,
            bac_spe_techno: formattedData.bac_spe_techno || null,
            bac_spe_pro: formattedData.bac_spe_pro || null
        };

        if (formattedData.bacSpeGenerals) {
            formattedData.bacSpeGenerals.forEach((value, index) => {
                data[`bac_spe_general${index + 1}`] = value || null;
            });
        }

        try {
            response = await AxiosInstance.post('/user/signup', data);
            if (response.data?.status === 'success') {
                const { user_id, user_name, user_surname, user_email, access_token } = response.data.data;
                localStorage.setItem('user_id', CryptoService.encryptData(user_id.toString()));
                localStorage.setItem('user_name', CryptoService.encryptData(user_name.toString()));
                localStorage.setItem('user_surname', CryptoService.encryptData(user_surname.toString()));
                localStorage.setItem('user_email', CryptoService.encryptData(user_email.toString()));
                localStorage.setItem('access_token', CryptoService.encryptData(access_token.toString()));

                setIsAuthenticated(true);
                return response;
            } else {
                console.error("Unexpected API response structure", response);
            }

        } catch (error) {
            console.error('Signup failed:', error);
            return response;
        }
    };

    const confirmEmail = async (otp, email) => {
        try {
            let response = await AxiosInstance.post('/user/confirm-email', {}, {
                params: {
                    token: otp,
                    email: email
                }
            });

            if (response.data.status === 'success') {
                setIsAuthenticated(true);
            }
            return response;
        } catch (error) {
            // Handle confirm email error
            console.error('Confirm email failed:', error);
            throw error;
        }
    };


    let checkEmailGoogle = async (email, providerAuth) => {
        try {
            let response = await AxiosInstance.post('/user/check-email-google', {}, {
                params: {
                    new_email: email,
                    id_provider_auth: providerAuth
                }
            });
            if (response.data.status === 'success') {
                setIsAuthenticated(true);
            }
            return response;
        } catch (error) {
            console.error('checkEmailGoogle failed:', error);
            throw error;
        }
    }
    const logout = () => {
        LocalStorageService.logout();
        setIsAuthenticated(false);
        navigate('/landing');
    };

    let googleLogin = async (token) => {
        try {
        } catch (error) {
            console.error('Google login failed:', error);
            throw error;
        }
    };

    let googleSignUp = async (token) => {
        try {

        } catch (error) {
            console.error('Google signUp failed:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, signUp, googleSignUp, googleLogin, confirmEmail, checkEmailGoogle, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

// Use Auth Context
export function useAuth() {
    const context = useContext(AuthContext);
    return context;
}

