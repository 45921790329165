import React from "react";

const HandIcon =()=>{
    return(
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.2494 9.97681L8.81195 5.75493C8.5638 5.3243 8.49678 4.81277 8.62562 4.33275C8.75446 3.85273 9.06861 3.4435 9.49903 3.19499C9.92945 2.94649 10.4409 2.87904 10.9211 3.00748C11.4012 3.13591 11.8107 3.44972 12.0595 3.87993L15.8095 10.3751"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.37699 12.5013L4.81449 6.33089C4.69113 6.11764 4.611 5.88216 4.57869 5.63793C4.54639 5.3937 4.56252 5.14549 4.62619 4.9075C4.68985 4.66951 4.79979 4.44639 4.94972 4.25091C5.09966 4.05543 5.28665 3.89141 5.5 3.76823C5.71335 3.64505 5.94889 3.56512 6.19315 3.53302C6.43741 3.50091 6.6856 3.51726 6.92354 3.58112C7.16148 3.64498 7.3845 3.75511 7.57986 3.90521C7.77522 4.0553 7.93908 4.24243 8.06208 4.45589L11.2496 9.9768"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.437 15.4977C13.9397 14.6364 13.805 13.6128 14.0624 12.6521C14.3198 11.6914 14.9483 10.8724 15.8096 10.3751L14.8721 8.7513C14.7488 8.53805 14.6686 8.30257 14.6363 8.05834C14.604 7.81411 14.6202 7.5659 14.6838 7.32791C14.7475 7.08992 14.8574 6.8668 15.0074 6.67132C15.1573 6.47584 15.3443 6.31182 15.5576 6.18864C15.771 6.06546 16.0065 5.98553 16.2508 5.95343C16.495 5.92132 16.7432 5.93767 16.9812 6.00153C17.2191 6.06539 17.4421 6.17552 17.6375 6.32562C17.8328 6.47572 17.9967 6.66284 18.1197 6.8763L19.9947 10.1239C20.4874 10.9769 20.8073 11.9185 20.936 12.8951C21.0648 13.8718 20.9999 14.8642 20.745 15.8157C20.4902 16.7672 20.0504 17.6592 19.4508 18.4407C18.8511 19.2222 18.1034 19.878 17.2504 20.3705C16.3973 20.8631 15.4555 21.1827 14.4789 21.3112C13.5023 21.4398 12.5099 21.3746 11.5584 21.1196C10.6069 20.8645 9.71505 20.4245 8.93365 19.8247C8.15225 19.2249 7.49667 18.4771 7.00433 17.6239L3.06683 10.8039C2.81869 10.3733 2.75167 9.86178 2.8805 9.38176C3.00934 8.90174 3.32349 8.49251 3.75391 8.244C4.18433 7.9955 4.69581 7.92805 5.17594 8.05649C5.65607 8.18492 6.06556 8.49873 6.31442 8.92894L8.37692 12.5013"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.60258 22.5002C6.33137 21.6182 5.27171 20.4651 4.5 19.1241"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5 2.90625C17.3557 2.90614 18.1964 3.13139 18.9374 3.55936C19.6784 3.98733 20.2936 4.6029 20.7212 5.34416"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default HandIcon;