import forge from 'node-forge';

/**
 * Load the public key from environment variables and validate it.
 * @returns {Object} publicKey - The public key object.
 */
const loadPublicKey = () => {
    // const publicKeyPem = process.env.REACT_APP_PUBLIC_KEY;
    const publicKeyPem = process.env.REACT_APP_PUBLIC_KEY.replace(/\\n/g, '\n');

    if (!publicKeyPem) {
        throw new Error('Public key not found in environment variables');
    }

    try {
        
        return forge.pki.publicKeyFromPem(publicKeyPem);
    } catch (error) {
        throw new Error('Invalid public key format');
    }
};

/**
 * Encrypt data using the public key with RSA-OAEP padding and SHA-256.
 * @param {string} data - The data to encrypt.
 * @param {Object} publicKey - The public key object.
 * @returns {string} - Base64-encoded encrypted data.
 */
const encryptWithPublicKey = (data) => {
    const publicKey = loadPublicKey();

    if (typeof data !== 'string') {
        throw new Error('Invalid data format. Expected a string.');
    }

    try {
        const encryptedData = publicKey.encrypt(data, 'RSA-OAEP', {
            md: forge.md.sha256.create(),
            mgf1: {
                md: forge.md.sha1.create(),
            },
        });

        return forge.util.encode64(encryptedData);
    } catch (error) {
        throw new Error('Encryption failed: ' + error.message);
    }
};

export { encryptWithPublicKey };

