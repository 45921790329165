import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY


let encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
};

let decryptData = (encryptedData) => {
    if (!encryptedData) {
        console.warn('No encrypted data provided for decryption');
        return null;
    }

    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedData) {
            console.warn('Decryption failed. Result is an empty string.');
            return null;
        }

        return decryptedData;
    } catch (error) {
        console.error('Error during decryption:', error);
        return null;
    }
};


const encryptDataUrl = (data) => {
    const encrypted = CryptoJS.AES.encrypt(data.toString(), secretKey).toString();
    const base64UrlSafe = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64UrlSafe;
};

const decryptDataUrl = (encryptedData) => {
    const base64 = encryptedData.replace(/-/g, '+').replace(/_/g, '/');
    const bytes = CryptoJS.AES.decrypt(base64, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
};

const nameUrlFormat = (name) => {
    return name.trim()   
    .replaceAll('/', '-')
    .normalize("NFD") // Décompose les accents
    .replace(/[\u0300-\u036f]/g, "") // Supprime les diacritiques (accents)
    .replaceAll(' ', '-') // Remplace les espaces par des tirets
    .toLowerCase(); // Met en minuscules pour une URL cohérente
}

const nameUrlFormatVF = (name) => {
    return name
        .trim()
        .normalize("NFD") // Decompose accents
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritics (accents)
        .replace(/[^a-zA-Z0-9\s-]/g, "") // Remove any special characters except spaces and hyphens
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Replace multiple hyphens with a single one
        .toLowerCase(); // Convert to lowercase for URL consistency
};

export const CryptoService = {
    encryptData,
    decryptData,
    encryptDataUrl,
    decryptDataUrl,
    nameUrlFormat,
    nameUrlFormatVF
}