import React, { useEffect, useState } from 'react';
import { Progress, Typography } from 'antd';
import 'antd/dist/reset.css';

const { Text } = Typography;

export default function ProgressComponent({
    workEnvironmentText = "Environnement de travail",
    personalityText = "Personnalité",
    favoriteTopicsText = "Sujets préférés",
    currentPart,
    isAuth,
    progress = 0
}) {
    const [colors, setColors] = useState({
        [workEnvironmentText]: '#686B86',
        [personalityText]: '#686B86',
        [favoriteTopicsText]: '#686B86',
    });

    useEffect(() => {
     //   alert("H " + window.innerHeight + " w " + window.innerWidth)
        setColors((prevColors) => {
            const updatedColors = { ...prevColors };

            if (progress === 93) {
                updatedColors[favoriteTopicsText] = '#1B84FF';
                updatedColors[workEnvironmentText] = 'black';
                updatedColors[personalityText] = 'black';
            } else {
                updatedColors[currentPart] = '#1B84FF';
                const previousPart = Object.keys(prevColors).find(
                    (key) => prevColors[key] === '#1B84FF'
                );
                if (previousPart && previousPart !== currentPart) {
                    updatedColors[previousPart] = 'black';
                }
            }

            return updatedColors;
        });
    }, [currentPart, progress, workEnvironmentText, personalityText, favoriteTopicsText]);

    const sections = [
        { id: workEnvironmentText, label: workEnvironmentText },
        { id: personalityText, label: personalityText },
        { id: favoriteTopicsText, label: favoriteTopicsText }
    ];

    // Calculate the position of the circle based on the progress
    const progressPercentage = Math.min(progress, 100);
    const circlePosition = `calc(${progressPercentage}% - 4px)`;

    return (
        <div
        className={`fixed top-0 ${isAuth ? ' md:right-24' : 'left-0'} w-full z-50 bg-[#E9F2FB] sm:left-4 sm:right-4`}
        style={{ paddingTop: '50px' }}
    >
        <div className="max-w-4xl mx-auto px-4 py-2 md:px-6">
            {/* Ligne des sections */}
            <div className="hidden md:flex space-x-60">
                {sections.map((section) => (
                    <Text
                        key={section.id}
                        className="font-inter text-xs text-center"
                        style={{ color: colors[section.id] }}
                    >
                        {section.label}
                    </Text>
                ))}
            </div>
            
            {/* Progress bar */}
            <div className="relative w-full">
                <Progress
                    percent={progress}
                    strokeColor="#1681FF"
                    trailColor="#FFFFFF"
                    strokeWidth={6}
                    showInfo={false}
                    className="w-full"
                    style={{ height: '10px', minWidth: '150px' }}
                />
                <div
                    className="absolute top-2/3 transform -translate-y-1/2"
                    style={{
                        left: circlePosition,
                        width: '10px',
                        height: '10px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '50%',
                        border: '1px solid #1681FF',
                        transition: 'left 0.3s'
                    }}
                />
            </div>
        </div>
    </div>
    
    );
}
