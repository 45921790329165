import React, { useEffect, useState } from "react";
import svgPattern from "../images/progress.svg";
import { useUserService } from "../services/userforms.service";

// const progressColors = ['#246D81', '#764B2D', '#CF3B53', '#EEBD38', '#57AF12'];
const progressColors = ['#2D7A07', '#141943', '#2D7A07', '#141943', '#2D7A07'];

export default function TraitsDeCarctere({ traitList }) {
    const [tooltipVisible, setTooltipVisible] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize(); // Set the initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleTooltip = (id) => {
        setTooltipVisible(tooltipVisible === id ? null : id);
    };

    return (
        <div className="mt-1 px-3">
            {traitList?.length > 0 ? (
                traitList.map((item, index) => (
                    <div key={item.name} className="flex items-center justify-between my-2">
                        <div className="text-xs font-inter font-medium text-start w-1/4  mr-4 relative">
                            <p
                                className={`-mb-3 text-[#141943]  `}
                                onClick={() => isSmallScreen && toggleTooltip(item.name)}
                                onMouseEnter={() => !isSmallScreen && setTooltipVisible(item.name)}
                                onMouseLeave={() => !isSmallScreen && setTooltipVisible(null)}
                            >
                                {item.name}
                            </p>
                            {/*{tooltipVisible === item.personality_trait_id && (*/}
                            {/*    <div className="tooltip bg-gray-500 text-white p-2  rounded absolute z-10">*/}
                            {/*        {item.personality_trait}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>

                        <div className="flex-1 ml-3 mt-3.5">
                            <div className="relative flex items-center -mt-1">
                                {/* Image container wrapping the progress bar */}
                                <div
                                    className="relative rounded-md h-7 w-full px-1"
                                    style={{
                                        backgroundImage: `url(${svgPattern})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        zIndex: 2,
                                    }}
                                >
                                    {/* Progress bar */}
                                    <div
                                        className="w-full  mt-1   bg-transparent overflow-hidden"
                                        role="progressbar"
                                        aria-valuenow={item.percentage}
                                        aria-valuemin="10"
                                        aria-valuemax="100"
                                    >
                                        <div
                                            className="relative flex items-center justify-end text-xs  h-5  text-white pr-1 rounded-sm "
                                            style={{
                                                backgroundColor: progressColors[index % progressColors.length],
                                                width: `${item.percentage}%`,
                                            }}
                                        >
                                            {item.percentage}%
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))
            ) : (
                <div className="animate-pulse p-1 mt-1">
                    <div className="h-6 bg-gray-300 rounded-md mb-2"></div>
                    <div className="h-6 bg-gray-300 rounded-md mb-2"></div>
                    <div className="h-6 bg-gray-300 rounded-md mb-2"></div>
                    <div className="h-6 bg-gray-300 rounded-md mb-2"></div>
                    <div className="h-6 bg-gray-300 rounded-md mb-2"></div>
                    <div className="h-6 bg-gray-300 rounded-md "></div>
                </div>
            )}
        </div>
    );
}
