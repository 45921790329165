import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../services/auth.provider";
import { LocalStorageService } from "../../services/localStorage.service";

function IsAccessibleRoute({ children }) {
  const { isAuthenticated } = useAuth();

  // if (isAuthenticated) {
    
  // }
  
  return isAuthenticated || LocalStorageService.isMoteur() ? children : <Navigate to="/landing" replace />;
}

export default IsAccessibleRoute;