import React, { useState, useRef, useEffect } from 'react';
import SituationStep from "./steps/SituationStep";
import StartFormationStep from "./steps/StartFormationStep";
import CloseToFinishStep from "./steps/CloseToFinishStep";
import SignUpStep from './steps/SignUpStep';
import Filiere from './steps/Filiere';
import SpecialiteStep from "./steps/SpecialiteStep";
import { LocalStorageService } from './../services/localStorage.service';
import NiveauActuel from './steps/NiveauActuel';
import BacSpeProStep from './steps/BacSpeProStep';
import BacSpeTechnoStep from './steps/BacSpeTechnoStep';
import BacSpeGeneralStep from './steps/BacSpeGeneralStep';
import { useAuth } from '../services/auth.provider';
import { useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Button, Progress } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../style/step.css' 

const { Content, Footer } = Layout;


export default function StepperPage() {
    const [stepData, setStepData] = useState(() => LocalStorageService.getData() || {});
    const [currentStep, setCurrentStep] = useState(1);
    const toastRef = useRef(null);
    const [prevSpecialiteStep, setPrevSpecialiteStep] = useState(null);
    const [errors, setErrors] = useState({}); // État pour les erreurs de validation
    const { signUp } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        LocalStorageService.saveData(stepData);
    }, [stepData]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            document.body.classList.remove("overflow-y-hidden");
        };
    }, []);

    const handleResize = () => {
        const screenHeight = window.innerHeight;
        console.log("screenHeight", screenHeight);
        console.log("screenWidth", window.innerWidth);
        document.body.classList.add("overflow-y-hidden");

        if (window.matchMedia("(min-width: 1024px)").matches) {
            document.body.classList.add("overflow-y-hidden");
        } else {
            document.body.classList.remove("overflow-y-hidden");
        }
    };

    const handleFinish = async () => {
        try {
            LocalStorageService.clearData();
            alert('Finished and user data saved!');
        } catch (error) {
            console.error("Error during sign-up or data save:", error);
            showMessage('An error occurred during sign-up or data save.', 'error');
        }
    };

    const renderBacStepFour = () => {
        if (stepData[1] === 1) {
            switch (stepData[3]) {
                case 1:
                    return <BacSpeGeneralStep previousSelection={stepData[2]} selectedData={stepData[4]} setSelectedData={data => handleDataUpdate(4, data, false)} />;
                case 2:
                    return <BacSpeTechnoStep selectedData={stepData[4]} setSelectedData={data => handleDataUpdate(4, data, true)} />;
                case 3:
                    return <BacSpeProStep selectedData={stepData[4]} setSelectedData={data => handleDataUpdate(4, data, true)} />;
                default:
                    return null;
            }
        }
        return null;
    };

    const renderStepThree = () => {
        if (stepData[1] === 1) {
            //3a
            return <Filiere selectedData={stepData[3]} setSelectedData={data => handleDataUpdate(3, data)} />;
        } else {
            return <SpecialiteStep selectedData={stepData[3]} setSelectedData={data => handleDataUpdate(3, data, false)} />;
        }
    };

    const stepComponents = {
        1: <SituationStep selectedData={stepData[1]} setSelectedData={data => handleDataUpdate(1, data)} />,
        // 2a, 2b, 2c
        2: <NiveauActuel previousSelection={stepData[1]} selectedData={stepData[2]} setSelectedData={data => handleDataUpdate(2, data)} />,
        3: renderStepThree(),
        4: renderBacStepFour(),
        //    5: <StartFormationStep selectedData={stepData[5]} setSelectedData={data => handleDataUpdate(5, data)} />,
        5: <CloseToFinishStep data={stepData[6]} setUserData={(data) => handleDataUpdate(6, data, false)} errors={errors} />, // Ajout des erreurs ici
        6: <SignUpStep onSubmit={handleFinish} />
    };

    // const handleNext = async () => {
    //     if (currentStep >= 5 && LocalStorageService.isLoggedIn()) {
    //         console.log("STEP --> ", currentStep, "User is already logged in");
    //
    //         const email = LocalStorageService.getEmail();
    //         const sub = LocalStorageService.getSub();
    //
    //         try {
    //             const response = await signUp(email, null, sub);
    //             if (response.data.status === 'success') {
    //                 navigate("/formation");
    //             } else {
    //                 console.error("Error during sign-up");
    //             }
    //         } catch (error) {
    //             console.error("Error during sign-up:", error);
    //         }
    //     } else if (validateStepData(currentStep)) {
    //         if (currentStep === 3 && stepData[1] !== 1) {
    //             setPrevSpecialiteStep(3);
    //             setCurrentStep(5);
    //         } else if (currentStep === 1 && stepData[1] === 5) {
    //             setPrevSpecialiteStep(1);
    //             setCurrentStep(5);
    //         } else {
    //             setCurrentStep(prev => prev + 1);
    //         }
    //     }
    // };

    const handleNext = async () => {
        const isValid = await validateStepData(currentStep); // Validate inputs

        if (!isValid) {
            console.warn("Validation failed. Please fix the errors before proceeding.");
            return; // Stop execution if validation fails
        }

        if (currentStep >= 5 && LocalStorageService.isLoggedIn()) {
            console.log("STEP -->", currentStep, "User is already logged in");

            const email = LocalStorageService.getEmail();
            const sub = LocalStorageService.getSub();

            try {
                const response = await signUp(email, null, sub);
                if (response?.data?.status === 'success') {
                    navigate("/formation");
                } else {
                    console.error("Error during sign-up");
                }
            } catch (error) {
                console.error("Error during sign-up:", error);
            }
        } else {
            // Handle navigation logic for other steps
            if (currentStep === 3 && stepData[1] !== 1) {
                setPrevSpecialiteStep(3);
                setCurrentStep(5);
            } else if (currentStep === 1 && stepData[1] === 5) {
                setPrevSpecialiteStep(1);
                setCurrentStep(5);
            } else {
                setCurrentStep(prev => prev + 1);
            }
        }
    };

    const handleBack = () => {
        let prevStep = currentStep - 1;
        if (currentStep === 5 && prevSpecialiteStep !== null) {
            prevStep = prevSpecialiteStep;
            setPrevSpecialiteStep(null);
        } else if (currentStep === 5 && (stepData[1] === 5 || stepData[2] === 1 || (stepData[1] !== 1 && stepData[3] !== undefined))) {
            prevStep = 1; // Skip back to step 1 if certain conditions are met
        }

        setCurrentStep(prevStep);
    };

    const showMessage = (message, severity) => {
        toastRef.current.show({ severity, summary: message, life: 1000 });
    };

    const handleDataUpdate = (step, data, autoAdvance = true) => {

        setStepData(prevData => {
            const newData = { ...prevData, [step]: data };

            let nextStep = step;
            if (step === 1 && data === 5) {
                nextStep = 5;

            } else if (step === 2 && data === 1) {
                nextStep = 5;
            } else if (step === 3 && [2, 3, 4].includes(prevData[1])) {

                nextStep = 5;
            } else if (autoAdvance) {
                nextStep = step + 1;
            }

            if (autoAdvance) {
                setTimeout(() => {
                    setCurrentStep(nextStep);
                }, 500);
            }
            return newData;
        });
    };

    const validateStepData = async (step) => {
        if (step >= 5) {
            let stepData = JSON.parse(localStorage.getItem('stepData')) || {};
            // console.log(stepData, "stepData");
            const stepsData = stepData.data || {};
            const checkData = stepsData[6];
            // console.log(checkData, "checkData");

            let newErrors = {};

            if (!checkData.user_name || !checkData.user_name.trim()) newErrors.user_name = 'Le nom est requis';
            if (!checkData.user_surname || !checkData.user_surname.trim()) newErrors.user_surname = 'Le prénom est requis';
            if (!checkData.country) newErrors.country = 'Le pays est requis';
            if (!checkData.user_year_of_birth || !checkData.user_year_of_birth.trim()) newErrors.user_year_of_birth = 'L\'année de naissance est requise';
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0;
        } else {
            const data = stepData[step];
            return data != null;
        }
    };

    return (
        <Layout className="relative w-full min-h-screen bg-white">
            <div className="absolute top-6 left-0 w-full px-2">
                <Progress
                    percent={(currentStep / 7) * 100}
                    strokeColor="#1681FF"
                    showInfo={false}
                    className="rounded-full"
                />
            </div>
    
            <Content className="flex-grow mt-10 mb-10 px-4 md:px-0">
                {stepComponents[currentStep]}
            </Content>
    
            <Footer className={`w-full bg-white px-2 mb-16 sm:mb-32 md:mb-52`}>
                <Row justify="space-between" align="middle" gutter={[16, 16]} className="footer-buttons">
                    <Col xs={24} sm={10} md={6}>
                        {currentStep > 1 && (
                            <Button
                                type="default"
                                onClick={handleBack}
                                block
                                icon={<LeftOutlined />}
                            >
                                Retour
                            </Button>
                        )}
                    </Col>
                    <Col xs={24} sm={10} md={6}>
                        {currentStep <= 5 && (
                            <Button
                                type="primary"
                                onClick={handleNext}
                                block
                                iconPosition="right"
                            >
                                Suivant
                                <RightOutlined className="ml-1" />
                            </Button>
                        )}
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
    

}
