import React, { useState } from "react";
import FooterLogo from "../images/landingpage/footerlogo.svg";
import Insta from "../images/social/insta";
import { Link } from 'react-router-dom';
import Fb from "../images/social/fb";
import { Linkedin } from "../images/social/Linkedin";
import { Youtube } from "../images/social/youtube";
import Handicon from "../images/landingpage/handicon";
import ConditionsGeneralesDialog from "../utils/ConditionsGenerales";
import MentionsLegalesDialog from "../utils/MentionsLegale";
import LoginModal from "./modal/auth/LoginModal";
import {useRef} from "react";
import { Toast } from 'primereact/toast';
import {notification} from "antd";

export default function Footer() {
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);
    const [openMentionsDialog, setOpenMentionsDialog] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const toast = useRef(null);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => setIsModalVisible(false);
    const showPopup = () => {
        notification.info({
            message: 'Bientôt Disponible',
            description: 'Cette fonctionnalité sera bientôt disponible.',
            placement: 'top',
        });
    }

    return (
        <div>
            <Toast ref={toast} position="top-center" />

            <div className="bg-[#04071C] text-white p-4">
                <div className="container mx-auto flex flex-col items-center text-center space-y-4">
                    <img src={FooterLogo} alt="Logo" className="w-48 md:w-56" />

                    <p className="text-gray-100 max-w-sm md:max-w-md">
                        Faites le test, trouvez le métier de vos rêves et découvrez votre formation idéale dès aujourd'hui !
                    </p>

                    <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 items-center justify-center">
                        <Link to="/public" className="flex-grow w-full md:w-auto">
                            <button className="w-full h-14 bg-[#1681FF] text-white font-bold py-2 px-6 rounded-md flex items-center justify-center space-x-2">
                                <span>Passez le test</span>
                                <Handicon />
                            </button>
                        </Link>
                        <Link to="/metiers" className="flex-grow w-full md:w-auto">
                            <button className="w-full h-14 bg-[#1681FF] text-white font-bold py-2 px-6 rounded-md text-sm md:text-base flex items-center justify-center">
                                Trouver mon métier de rêve
                            </button>
                        </Link>
                        <Link to="/formation" className="flex-grow w-full md:w-auto">
                            <button className="w-full h-14 bg-[#1681FF] text-white font-bold py-2 px-6 rounded-md text-sm md:text-base flex items-center justify-center">
                                Trouver ma formation
                            </button>
                        </Link>
                    </div>

                </div>

                <hr className="my-6 border-gray-600" />

                <div className="flex flex-col md:flex-row justify-between items-center md:items-start space-y-5 md:space-y-0">
                    <div className="flex flex-col items-center md:items-start space-y-2">
                        <div className="flex space-x-2">
                            <Link to={`https://www.instagram.com/linkyways/`}>
                                <button className="text-gray-400 hover:text-white rounded-full bg-gray-900 p-2">
                                    <Insta />
                                </button>
                            </Link>
                            <Link to={`https://www.facebook.com/share/77tdxteos8kdTcpM/?mibextid=LQQJ4d`}>
                            <button className="text-gray-400 hover:text-white rounded-full bg-gray-900 p-2">
                                <Fb />
                            </button>
                            </Link>
                            <Link to={`https://fr.linkedin.com/company/linkyways`}>
                            <button className="text-gray-400 hover:text-white rounded-full bg-gray-900 p-2">
                                <Linkedin />
                            </button>
                            </Link>
                            <Link to={`https://www.youtube.com/@Linkyways`}>
                            <button className="text-gray-400 hover:text-white rounded-full bg-gray-900 p-2">
                                <Youtube />
                            </button>
                            </Link>
                        </div>

                        <p className="text-gray-400 text-center md:text-left">
                            © Linkyways 2024
                        </p>
                    </div>

                    <div className="flex flex-row items-center space-x-4 text-center md:text-left">
                        <Link to={'/public/registration'}>
                            <p>S'inscrire</p>
                        </Link>
                        <div className="flex items-center gap-2 cursor-pointer "
                             onClick={showModal}
                        >
                            <p>S’identifier</p>
                            <svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-3">
                                <circle cx="17" cy="17" r="16.5" stroke="#EAEAEA" />
                                <path d="M17 20C20.3137 20 23 17.3137 23 14C23 10.6863 20.3137 8 17 8C13.6863 8 11 10.6863 11 14C11 17.3137 13.6863 20 17 20Z" stroke="#EAEAEA" strokeMiterlimit="10" />
                                <path d="M7.90527 25.2491C8.82736 23.6531 10.1532 22.3278 11.7497 21.4064C13.3461 20.485 15.1569 20 17.0002 20C18.8434 20 20.6542 20.4851 22.2506 21.4065C23.8471 22.3279 25.1729 23.6533 26.0949 25.2493" stroke="#EAEAEA" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>


                    <div className="flex flex-col items-center md:items-end space-y-2">
                        <Link to={'/mentions_legales'}>
                        <p className="text-gray-400 text-center md:text-right underline cursor-pointer"
                           onClick={() => setOpenMentionsDialog(true)}
                        >
                                <p>Mentions légales</p>
                        </p>
                        </Link>

                        <Link to={'/conditions_generales'}>
                        <p
                            className="text-gray-400 text-center md:text-right underline cursor-pointer"
                            onClick={() => setOpenConditionsDialog(true)}
                        >
                                <p>Conditions générales</p>
                        </p>
                        </Link>
                    </div>
                </div>
            </div>

            <ConditionsGeneralesDialog open={openConditionsDialog} onClose={() => setOpenConditionsDialog(false)} />
            <MentionsLegalesDialog open={openMentionsDialog} onClose={() => setOpenMentionsDialog(false)} />
            <LoginModal visible={isModalVisible} onHide={hideModal} />

        </div>
    );
}
