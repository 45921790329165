import React from 'react';
import BgImg from "../images/bgimage.svg";
import { Link } from 'react-router-dom';
import logo from "../images/mobileTopBg.svg";
import StepperPage from './Stepper.component';
import { useAuth } from '../services/auth.provider';

export default function Registration() {
    const { logout } = useAuth();

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await logout();
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <div className="flex flex-col lg:flex-row min-h-screen">
            <div className="relative lg:block lg:w-1/5 bg-gray-200">
                <Link onClick={handleLogout}> {/* to={"/landing"} */}
                    <img
                        alt="Background"
                        src={BgImg}
                        className="object-cover w-full h-full hidden lg:block" />
                    <img
                        alt="laststep"
                        src={logo}
                        className="w-full  lg:hidden object-cover" />
                </Link>
            </div>

            <div className="w-full lg:w-4/5 bg-white flex flex-col justify-between">
                <div className="max-w-2xl w-full mx-auto flex-grow flex flex-col justify-center ">
                    <StepperPage />
                </div>
            </div>
        </div>
    );
}