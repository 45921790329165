import Bgimg from "../images/landingpage/landingbg.png";
import Sideimg from "../images/landingpage/sideimage.png";
import allimages from "../images/landingpage/allimages.png";
import React from "react";
import {Link} from 'react-router-dom';

import Handicon from "../images/landingpage/handicon";
import PartnerCarousel from "../utils/PartnersCarousel";
import FirstSideIcon from "../images/landingpage/firstSideIcon";
import SecondSideIcon from "../images/landingpage/secondSideIcon";
import ThirdSideIcon from "../images/landingpage/thirdSideIcon";
import Footer from "../components/Footer";
import LandingNav from "../components/LandingNav";
import {useRef} from "react";
import {Toast} from "primereact/toast";
import {notification} from "antd";

export default function LandingPage() {
    const toast = useRef(null);
    // localStorage.clear()
    const showPopup = () => {
        notification.info({
            message: 'Bientôt Disponible',
            description: 'Cette fonctionnalité sera bientôt disponible.',
            placement: 'top',
        });
    }
    return (
        <>
            <LandingNav/>
            <Toast ref={toast} position="top-center" />

            <div className="relative w-full h-[600px] rounded-xl bg-cover bg-center"
                 style={{backgroundImage: `url(${Bgimg})`}}>
                <div
                    className="absolute top-20 left-0 right-0 flex flex-col justify-center items-center text-center ">
                    <p className="font-bold text-2xl md:text-6xl text-[#141943]">
                        Découvrez le métier de
                    </p>
                    <p className="font-bold -mt-4 text-2xl md:text-6xl text-[#141943]">
                        vos rêves en {" "}
                        <span className="text-blue-500  relative inline-block w-full md:w-auto">10 minutes
                            <span className="absolute left-0 right-0 top-full flex justify-center">
                                <svg
                                    className="w-24 md:w-48 lg:w-64" height="18" viewBox="0 0 300 18" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3 15.4998C19.5 8.49985 46.5356 8.17184 69.7376 5.93085C84.5 4.505 229.5 -0.00024426 297.243 4.50497"
                                        stroke="#FFD11F" strokeWidth="5" strokeLinecap="round"/>
                                </svg>
                            </span>
                        </span>
                    </p>

                    <div
                        className="relative flex flex-col md:flex-row justify-between items-center   pt-2  pb-10   mx-1 ">
                        <div
                            className="flex flex-col md:flex-row justify-between items-center gap-2 md:gap-6  px-2 md:px-4 w-full">
                            <p className="font-inter   text-sm text-center text-[#434769]">
                                Découvrez les métiers adaptés à votre <br/> personnalité avec la méthode IKIGAI
                            </p>
                            <p className="font-inter    text-sm text-canter text-[#434769]">
                                Explorez parmi plus de 2000 métiers <br/> dans 40 secteurs d'activité
                            </p>
                            <p className="font-inter    text-sm text-canter text-[#434769]">
                                Identifiez les parcours académiques <br/> les plus adaptés à vos objectifs
                            </p>

                        </div>

                    </div>
                    <Link to="/public">
                        <button
                            className="bg-[#1681FF] -mt-2 text-white font-bold py-2 px-6 rounded-md flex items-center space-x-2 ">

                            <span>Passez le test </span>
                            <Handicon/>
                        </button>
                    </Link>
                    <div className="w-full">
                        <img src={allimages} className="w-full object-cover"/>
                    </div>
                    <div className="mt-5 w-full mx-1  mb-10">
                        <PartnerCarousel autoplayInterval={3000}/>
                    </div>

                    <div
                        className="hidden md:flex w-full  flex-col mt-5 md:flex-row items-center md:items-start justify-between md:space-x-4 md:pl-8">
                        <div className="w-full md:w-1/2 mb-4 md:mb-0 pl-2 md:pl-4 mt-1 md:mt-5">
                            <h2 className="text-4xl font-poppins font-bold  text-start">
                                Trouvez les formations les plus adaptées à votre projet professionnel
                            </h2>
                            <div className="space-y-4 mt-5">
                                <div className="flex items-center">
                                    <div className="max-w-8rem">
                                        <FirstSideIcon />

                                    </div>
                                    <p className=" ml-2 text-start text-gray-700">
                                        Linkyways vous met en relation avec votre futur établissement d'enseignement supérieur
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <div className="max-w-8rem">
                                    <SecondSideIcon/>
                                    </div>
                                    <p className="ml-2 text-start text-gray-700">
                                        Découvrez les passerelles entre les formations et les parcours possibles (Bientôt disponible)
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <div className="max-w-8rem">
                                    <ThirdSideIcon/>
                                    </div>
                                    <p className="ml-2 text-start text-gray-700">
                                        50 000 formations Postback et Master référencées (Bientôt disponible)
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-4 mt-4 justify-center md:justify-start">
                                <Link to="/metiers">
                                    <button className="bg-[#1681FF] text-white font-bold py-2 px-4 rounded-md">
                                        Trouver mon métier de rêve
                                    </button>
                                </Link>
                                <Link to="/formation">
                                <button
                                    className="bg-transparent text-[#1681FF] border border-[#1681FF] font-bold py-2 px-4 rounded-md">
                                    Trouver ma formation
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 ">
                            <img
                                src={Sideimg}
                                alt="side"
                                className="w-full object-cover"
                            />
                        </div>
                    </div>
                    {/* For small screens */}
                    <div className="md:hidden relative mt-3">
                        <h2 className="text-2xl  font-poppins font-bold mb-5 text-start w-full p-2  ">
                            Trouvez les formations les plus adaptées à votre projet professionnel
                        </h2>
                        <img
                            src={Sideimg}
                            alt="side"
                            className="object-cover mt-5"
                        />

                        <div className="space-y-4 mt-5 p-2">
                            <div className="flex flex-col md:flex-row md:items-center md:space-x-8">
                                <div className="flex items-center md:flex-1">
                                    <FirstSideIcon className="w-6 h-6 flex-shrink-0"/>
                                    <p className="ml-4 text-gray-700 text-lg font-medium">Linkyways vous met en relation avec votre futur établissement d'enseignement supérieur</p>
                                </div>
                                <div className="flex items-center md:flex-1 mt-4 md:mt-0">
                                    <SecondSideIcon className="w-6 h-6 flex-shrink-0"/>
                                    <p className="ml-4 text-gray-700 text-lg font-medium">Découvrez les passerelles entre les formations et les parcours possibles (Bientôt disponible)</p>
                                </div>
                                <div className="flex items-center md:flex-1 mt-4 md:mt-0">
                                    <ThirdSideIcon className="w-6 h-6 flex-shrink-0"/>
                                    <p className="ml-4 text-gray-700 text-lg font-medium">50 000 formations Postback et Master référencées (Bientôt disponible)</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col space-y-4 mt-4 p-4">
                            <Link to="/metiers">
                                <button className="bg-[#1681FF] text-white font-bold py-2 px-4 rounded-md w-full">
                                    Trouver mon métier de rêve
                                </button>
                            </Link>
                            <Link to="/formation">
                                <button
                                    className="bg-transparent text-[#1681FF] border border-[#1681FF] font-bold py-2 px-4 rounded-md w-full">
                                    Trouver ma formation
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="w-full">
                        <Footer/>
                    </div>

                    <div>

                    </div>

                </div>

            </div>

        </>
    );
}
