// CustomCheckbox.js
import React from "react";

const CustomCheckbox = ({ label, isChecked, onToggle }) => {
    return (
        <div className="flex items-center border rounded-md bg-white w-full border-[#D0DBE7] p-2 cursor-pointer">
            <div onClick={onToggle} className="mr-2">
                {isChecked === "1" ? (
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="16" height="16" rx="3" fill="#141943" />
                        <path d="M11.4375 6.625L6.85414 11L4.5625 8.8125" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                ) : (
                    <svg width="16" height="17" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="2.5" y="3" width="15" height="15" rx="2.5" stroke="#141943" />
                    </svg>
                )}
            </div>
            <span className="text-[12px] md:text-[14px] font-medium font-inter text-black">{label}</span>
        </div>
    );
};

export default CustomCheckbox;
