//
// import CustomSelectSearch from "./dropDown/CustomSelectSearch";
// import BrushIcon from "../images/brush.svg";
// import React, { useState, useEffect } from "react";
// import CustomCheckbox from "./FormationComponents/CustomCheckbox";
// import { useFormationService } from "../services/formation.service";
// import MultiCustomSelect from "./dropDown/MultiCustomSelect";
//
// export default function FormationFilters({ checkboxValues, onCheckboxChange ,onSelectAccessChange, onSelectAreaChange,onSelectStudLevel,clearFilters}) {
//     const { getAreaStudies, getAccessRate, getTrainingType, getSearchPostBac } = useFormationService();
//
//     const [area, setArea] = useState([]);
//     const [rate, setAccessRate] = useState([]);
//     const [training, setTraining] = useState([]);
//     // const [clearFilters, setClearFilters] = useState(false); // Add this state
//
//     useEffect(() => {
//         const fetchAreaStudies = async () => {
//             try {
//                 const response = await getAreaStudies();
//                 setArea(response.data.data);
//             } catch (error) {
//                 console.error("Error fetching area:", error);
//             }
//         };
//
//         const fetchAccessRate = async () => {
//             try {
//                 const response = await getAccessRate();
//                 setAccessRate(response.data.data);
//             } catch (error) {
//                 console.error("Error fetching access rate:", error);
//             }
//         };
//
//         const fetchTrainingType = async () => {
//             try {
//                 const response = await getTrainingType();
//                 setTraining(response.data.data);
//             } catch (error) {
//                 console.error("Error fetching training:", error);
//             }
//         };
//
//         fetchAreaStudies();
//         fetchAccessRate();
//         fetchTrainingType();
//     }, []);
//
//     const handleTrainigChange = (selectedTraining) => {
//         console.log("Selected training:", selectedTraining);
//         onSelectStudLevel(selectedTraining);
//     };
//     const handleAreaChange = (selectedArea) => {
//         console.log("Selected area:", selectedArea);
//         onSelectAreaChange(selectedArea);
//     };
//
//     const handleAccess = (selectedAccess) => {
//         console.log("Selected training:", selectedAccess);
//         onSelectAccessChange(selectedAccess);
//     };
//
//     const handleCheckboxChange = (key) => {
//         onCheckboxChange(key); // Call the handler passed from the parent component
//     };
//
//     // useEffect(() => {
//     //     if (clearFilters) {
//     //         onSelectAccessChange(null); // Clear access rate
//     //         onSelectAreaChange(null); // Clear area
//     //         onSelectStudLevel(null); // Clear training
//     //     }
//     // }, []);
//
//
//     return (
//         <div className="md:px-0 px-3">
//             <div className="w-full flex flex-wrap justify-between items-center py-2">
//                 <div className="flex md:hidden mb-2 justify-between space-x-1 w-full">
//                     <CustomCheckbox
//                         label="Alternance"
//                         isChecked={checkboxValues.alternance}
//                         onToggle={() => handleCheckboxChange('alternance')}
//
//                     />
//                     <CustomCheckbox
//                         label="En Distanciel"
//                         isChecked={checkboxValues.enDistanciel}
//                         onToggle={() => handleCheckboxChange('enDistanciel')}
//                     />
//                     <CustomCheckbox
//                         label="Public"
//                         isChecked={checkboxValues.public}
//                         onToggle={() => handleCheckboxChange('public')}
//                     />
//                 </div>
//                 <div className="flex flex-wrap gap-2 w-full lg:w-auto">
//                     <div className="w-full sm:w-1/2  lg:w-auto">
//                         <MultiCustomSelect
//                             placeholder="Type de formation"
//                             options={training}
//                             onChange={handleTrainigChange}
//                             optionKey="ref"
//                             optionLabel="type_training"
//                             width={"170px"}
//                             clearFilters={clearFilters}
//                         />
//                     </div>
//                     <div className="w-full sm:w-1/2 lg:w-auto">
//                         <MultiCustomSelect
//                             placeholder="Filière"
//                             options={area}
//                             onChange={handleAreaChange}
//                             optionKey="areastud_id"
//                             optionLabel="area_studies"
//                             width={"80px"}
//                             clearFilters={clearFilters}
//                         />
//                     </div>
//                     <div className="hidden md:flex flex-wrap gap-2 w-full lg:w-auto justify-center space-x-2">
//                         <CustomCheckbox
//                             label="Alternance"
//                             isChecked={checkboxValues.alternance}
//                             onToggle={() => handleCheckboxChange('alternance')}
//                         />
//                         <CustomCheckbox
//                             label="En Distanciel"
//                             isChecked={checkboxValues.enDistanciel}
//                             onToggle={() => handleCheckboxChange('enDistanciel')}
//                         />
//                         <CustomCheckbox
//                             label="Public"
//                             isChecked={checkboxValues.public}
//                             onToggle={() => handleCheckboxChange('public')}
//                         />
//                     </div>
//                     <div className="w-full sm:w-1/2 lg:w-auto">
//                         <MultiCustomSelect
//                             placeholder="Taux d'accès"
//                             options={rate}
//                             onChange={handleAccess}
//                             optionKey="access_rate_range_id"
//                             optionLabel="access_rate_range"
//                             width={"120px"}
//                             clearFilters={clearFilters}
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

import React, { useState, useEffect } from "react";
import CustomCheckbox from "./FormationComponents/CustomCheckbox";
import MultiCustomSelect from "./dropDown/MultiCustomSelect";
import { useFormationService } from "../services/formation.service";
import {useAuth} from "../services/auth.provider";

export default function FormationFilters({
                                             checkboxValues,
                                             onCheckboxChange,
                                             onSelectAccessChange,
                                             onSelectAreaChange,
                                             onSelectStudLevel,
                                             clearFilters,
                                             selectedStudLevelIds,
                                             selectedAreaIds,
                                             selectedAccessRateIds,
                                         }) {
    const { getAreaStudies, getAccessRate, getTrainingType } = useFormationService();

    const [area, setArea] = useState([]);
    const [rate, setAccessRate] = useState([]);
    const [training, setTraining] = useState([]);
    const [initialStudLevelSelected, setInitialTrainingSelected] = useState([]);
    const [initialAreaSelected, setInitialAreaSelected] = useState([]);
    const [initialAccessSelected, setInitialAccessSelected] = useState([]);
    const { isAuthenticated } = useAuth();
    // useEffect(() => {
    //     // Consolidated data fetch function to avoid multiple calls
    //     const fetchData = async () => {
    //         try {
    //             const [areaResponse, accessRateResponse, trainingTypeResponse] = await Promise.all([
    //                 getAreaStudies(),
    //                 getAccessRate(),
    //                 getTrainingType(),
    //             ]);
    //
    //             setArea(areaResponse.data.data);
    //             setAccessRate(accessRateResponse.data.data);
    //             setTraining(trainingTypeResponse.data.data);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //
    //     fetchData(); // Only called on initial render
    // }, []);
    useEffect(() => {
        // Consolidated data fetch function to avoid multiple calls
        const fetchData = async () => {
            try {
                const [areaResponse, accessRateResponse, trainingTypeResponse] = await Promise.all([
                    getAreaStudies(),
                    getAccessRate(),
                    getTrainingType(),
                ]);

                setArea(areaResponse.data.data);
                setAccessRate(accessRateResponse.data.data);
                console.log("niveau",trainingTypeResponse.data.data)

                const filteredTrainingData = trainingTypeResponse.data.data.filter(item => item.etude_sup > 0);
                console.log("niveau",filteredTrainingData)
                setTraining(filteredTrainingData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // Only called on initial render
    }, []);


    useEffect(() => {
        if (training?.length > 0 && selectedStudLevelIds?.length > 0) {
            const selected = training.filter(option =>
                selectedStudLevelIds.includes(option.studlevel_id)
            );
            setInitialTrainingSelected(selected);
        } else {
            setInitialTrainingSelected([]);
        }
    }, [training, selectedStudLevelIds]);

    useEffect(() => {
        if (area?.length > 0 && selectedAreaIds?.length > 0) {
            const selected = area.filter(option =>
                selectedAreaIds.includes(option.areastud_id)
            );
            setInitialAreaSelected(selected);
        } else {
            setInitialAreaSelected([]);
        }
    }, [area, selectedAreaIds]);

    useEffect(() => {
        if (rate?.length > 0 && selectedAccessRateIds?.length > 0) {
            const selected = rate.filter(option =>
                selectedAccessRateIds.includes(option.access_rate_range_id)
            );
            setInitialAccessSelected(selected);
        } else {
            setInitialAccessSelected([]);
        }
    }, [rate, selectedAccessRateIds]);

    const handleStudLevelChange = (selectedTraining) => {
        console.log("Selected training:", selectedTraining);
        onSelectStudLevel(selectedTraining);
        if (isAuthenticated) {
            onSelectStudLevel(selectedTraining);
        }
    };

    const handleAreaChange = (selectedArea) => {
        console.log("Selected area:", selectedArea);
        if (isAuthenticated) {
            onSelectAreaChange(selectedArea);
        }
    };

    const handleAccess = (selectedAccess) => {
        console.log("Selected access rate:", selectedAccess);
        if (isAuthenticated) {
            onSelectAccessChange(selectedAccess);
        }
    };

    const handleCheckboxChange = (key) => {
        if (isAuthenticated) {
            onCheckboxChange(key);
        }
    };

    return (
        <div  >
            <div className="w-full flex flex-wrap justify-between items-center py-2">
                {/* Mobile Checkboxes */}
                <div className="flex md:hidden mb-2 justify-between  space-x-1 w-full">
                    <CustomCheckbox
                        label="Alternance"
                        isChecked={checkboxValues.alternance}
                        onToggle={() => handleCheckboxChange('alternance')}
                    />

                    <CustomCheckbox
                        label="Distanciel"
                        isChecked={checkboxValues.enDistanciel}
                        onToggle={() => handleCheckboxChange('enDistanciel')}
                    />
                    <CustomCheckbox
                        label="Public"
                        isChecked={checkboxValues.public }
                        onToggle={() => handleCheckboxChange('public')}
                    />
                </div>

                {/* Dropdowns */}
                <div className="flex flex-wrap gap-2 w-full lg:w-auto">
                    {/* Type de formation */}
                    <div className="w-full sm:w-1/2 lg:w-auto">
                        <MultiCustomSelect
                            placeholder="Niveau d'études"
                            options={training}
                            onChange={handleStudLevelChange}
                            initialSelectedOptions={initialStudLevelSelected}
                            optionKey="studlevel_id"
                            // optionLabel="type_training"
                            optionLabel="study_level"
                            width={"170px"}
                            clearFilters={clearFilters}
                        />
                    </div>

                    {/* Filière */}
                    <div className="w-full sm:w-1/2 lg:w-auto">
                        <MultiCustomSelect
                            placeholder="Filière"
                            options={area}
                            onChange={handleAreaChange}
                            initialSelectedOptions={initialAreaSelected}
                            optionKey="areastud_id"
                            optionLabel="area_studies"
                            width={"120px"}
                            clearFilters={clearFilters}
                        />
                    </div>

                    {/* Checkboxes for larger screens */}
                    <div className="hidden md:flex   lg:w-auto justify-center item-center w-full gap-2">
                        <CustomCheckbox
                            label="Alternance"
                            isChecked={checkboxValues.alternance }
                            onToggle={() => handleCheckboxChange('alternance')}
                        />
                        <CustomCheckbox
                            label="Distanciel"
                            isChecked={checkboxValues.enDistanciel}
                            onToggle={() => handleCheckboxChange('enDistanciel')}
                        />
                        <CustomCheckbox
                            label="Public"
                            isChecked={checkboxValues.public }
                            onToggle={() => handleCheckboxChange('public')}
                        />
                    </div>

                    {/* Taux d'accès */}
                    <div className="w-full sm:w-1/2 lg:w-auto">
                        <MultiCustomSelect
                            placeholder="Taux d'accès"
                            options={rate}
                            onChange={handleAccess}
                            initialSelectedOptions={initialAccessSelected}
                            optionKey="access_rate_range_id"
                            optionLabel="access_rate_range"
                            width={"120px"}
                            clearFilters={clearFilters}
                        />
                    </div>
                </div>
            </div>
        </div>
        // <div className="w-full">
        //     <div className="flex flex-wrap justify-between items-center py-2">
        //         {/* Mobile Checkboxes */}
        //         <div className="flex w-full md:hidden justify-between space-x-2">
        //             <CustomCheckbox
        //                 label="Alternance"
        //                 isChecked={checkboxValues.alternance}
        //                 onToggle={() => handleCheckboxChange('alternance')}
        //             />
        //             <CustomCheckbox
        //                 label="En Distanciel"
        //                 isChecked={checkboxValues.enDistanciel}
        //                 onToggle={() => handleCheckboxChange('enDistanciel')}
        //             />
        //             <CustomCheckbox
        //                 label="Public"
        //                 isChecked={checkboxValues.public}
        //                 onToggle={() => handleCheckboxChange('public')}
        //             />
        //         </div>
        //
        //         {/* Dropdowns and Checkboxes for larger screens */}
        //         <div className="hidden md:flex md:flex-wrap md:gap-2 lg:w-full items-center justify-between">
        //             <div className="flex gap-2">
        //                 <CustomCheckbox
        //                     label="Alternance"
        //                     isChecked={checkboxValues.alternance}
        //                     onToggle={() => handleCheckboxChange('alternance')}
        //                 />
        //                 <CustomCheckbox
        //                     label="En Distanciel"
        //                     isChecked={checkboxValues.enDistanciel}
        //                     onToggle={() => handleCheckboxChange('enDistanciel')}
        //                 />
        //                 <CustomCheckbox
        //                     label="Public"
        //                     isChecked={checkboxValues.public}
        //                     onToggle={() => handleCheckboxChange('public')}
        //                 />
        //             </div>
        //
        //             {/* Type de formation */}
        //             <div className="w-full md:w-auto">
        //                 <MultiCustomSelect
        //                     placeholder="Type de formation"
        //                     options={training}
        //                     onChange={handleStudLevelChange}
        //                     initialSelectedOptions={initialStudLevelSelected}
        //                     optionKey="ref"
        //                     optionLabel="type_training"
        //                     width={"170px"}
        //                     clearFilters={clearFilters}
        //                 />
        //             </div>
        //
        //             {/* Filière */}
        //             <div className="w-full md:w-auto">
        //                 <MultiCustomSelect
        //                     placeholder="Filière"
        //                     options={area}
        //                     onChange={handleAreaChange}
        //                     initialSelectedOptions={initialAreaSelected}
        //                     optionKey="areastud_id"
        //                     optionLabel="area_studies"
        //                     width={"80px"}
        //                     clearFilters={clearFilters}
        //                 />
        //             </div>
        //
        //             {/* Taux d'accès */}
        //             <div className="w-full md:w-auto">
        //                 <MultiCustomSelect
        //                     placeholder="Taux d'accès"
        //                     options={rate}
        //                     onChange={handleAccess}
        //                     initialSelectedOptions={initialAccessSelected}
        //                     optionKey="access_rate_range_id"
        //                     optionLabel="access_rate_range"
        //                     width={"120px"}
        //                     clearFilters={clearFilters}
        //                 />
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}
