import { useAxios } from './caller.service';

export const useAuthService = () => {
    const AxiosInstance = useAxios();

    let confirmEmail = async (otp, email) => {
        try {
            return await AxiosInstance.post('/user/confirm-email', {}, {
                params: {
                    token: otp,
                    email: email
                }
            });
        } catch (error) {
            console.error('Confirm email failed:', error);
            throw error;
        }
    }


    let resetPassword = async (email, newPassword) => {
        const data = {
            email: email,
            new_password: newPassword
        };
        try {
            return await AxiosInstance.post('/user/reset-password/', data);
        } catch (error) {
            console.error('Reset password failed:', error);
            throw error;
        }
    }

    let forgotPassword =  async (email) => {
        const data = { email };
        try {
            return await AxiosInstance.post('/user/forget-password/', data);
        } catch (error) {
            console.error('Forgot password failed:', error);
            throw error;
        }
    }

    let checkEmail =  async (newEmail, replaceEmail, replaceEmailStatus) => {
        let params = {};
        
        if (newEmail !== null) {
            params.new_email = newEmail;
        }
        
        if (replaceEmail !== null) {
            params.replace_email = replaceEmail;
        }

        params.replace_email_status = replaceEmailStatus;
        const queryString = new URLSearchParams(params).toString();
        return AxiosInstance.post(`/user/check-email/?${queryString}`);
    }

    return {
        confirmEmail,
        resetPassword,
        forgotPassword,
        checkEmail
        }
};
