import React from 'react';

const RatingComponent = ({
  ratingValue,
  starColor = 'text-yellow-400',
  inactiveStarColor = 'text-gray-300',
  size = 'w-8 h-8',
  onRatingChange,
}) => {

  const handleRatingClick = (value) => {
    if (onRatingChange) {
      onRatingChange(value);
    } else {
      console.error('onRatingChange function is not provided');
    }
  };

  return (
    <div className="flex items-center space-x-0">
      {[1, 2, 3].map((value) => (
        <React.Fragment key={value}>
          <input
            id={`rating-${value}`}
            type="radio"
            className="peer sr-only"
            name="rating"
            value={value}
            checked={ratingValue === value}
            onChange={() => handleRatingClick(value)}
            aria-checked={ratingValue === value}
          />
          <label
            htmlFor={`rating-${value}`}
            className={`cursor-pointer ${ratingValue >= value ? starColor : inactiveStarColor} transition-transform duration-150 ease-in-out transform`}
            aria-label={`Rate ${value} out of 3`}
            role="button"
          >
            <svg
              className={`inline ${size}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
            </svg>
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

export default RatingComponent;
