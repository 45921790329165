import LayoutNo from "../utils/LayoutNo";
import Igikai from "../images/igikai.svg";
import RedHeart from "../images/ikigai/redhearticon.svg";
import Money from "../images/ikigai/Money.svg";
import Charity from "../images/ikigai/charity.svg";
import Tool from "../images/ikigai/tool.svg";
import Handicon from "../images/landingpage/handicon";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useUserService } from "../services/userforms.service";
import { CryptoService } from "../services/crypte.service";
import { Button } from 'antd';

export default function QuiCeQueIkegai() {
    const [userCalculate, setUserCalculate] = useState();
    const [viewResult, setViewResult] = useState(false);
    const navigate = useNavigate();
    const { getUserCalculate } = useUserService();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            const decryptedId = CryptoService.decryptData(userId)
            const response = await getUserCalculate(decryptedId);
    
            if (Array.isArray(response.data) && response.data.data.length === 0) {
                setViewResult(false);
            } else {
                setUserCalculate(response.data.data);
                setViewResult(true);
            }
    
        } catch (error) {
            console.error("Error fetching user calculate:", error);
            setViewResult(false);
        }
    };
    

    const handleTestButtonClick = () => {
        navigate('/public');
    };

    const handleResultsButtonClick = () => {
        navigate('/guidance');
    };

    return (
        <LayoutNo>
            <div className="bg-transparent  mt-2  ">
                <div className="text-start mb-2 p-4">
                    <div className="flex justify-between">
                        <div className="justify-start">
                        </div>
                        <div className="justify-end ">
                            <div className="hidden lg:flex gap-2">
                                <Button type="primary" className="font-semibold text-md gap-3 "
                                        onClick={handleTestButtonClick}
                                >
                                    {viewResult ? 'Repassez le test ' : 'Passez le test' }
                                    <Handicon />

                                </Button>

                                {viewResult && (
                                    <Button onClick={handleResultsButtonClick} className="font-semibold text-md gap-3 ">
                                        Voir mes résultats
                                    </Button>
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="flex justify-between md:mt-0 mt-6 ">
                        <h2 className="text-2xl font-bold font-poppins text-[#141943] justify-start ">Qu’est-ce que l’IKIGAI ?</h2>
                    </div>
                    <p className="pt-2  font-inter font-normal text-[#141943]">L'ikigai est un concept japonais qui se réfère à la raison de vivre, à ce qui donne un sens à notre existence, à ce qui nous motive et nous épanouit profondément. Le terme "ikigai" est composé de deux mots japonais : "iki", qui signifie "vivre" ou "existence", et "gai", qui signifie "raison" ou "valeur". Ainsi, l'ikigai peut être compris comme "la raison de vivre" ou "la raison d'être".</p>
                </div>
            </div>
            <div className=" w-full  ">
                <div className=" flex justify-center px-4 py-2">

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3  ">
                        <div className=" p-2 rounded-lg shadow-lg border-1 p-4">
                            <img src={RedHeart} alt="Description of image" className="p-1" />
                            <h3 className="text-md mt-2 font-semibold font-poppins text-[#141943] md:min-h-[3rem] overflow-hidden "   >Ce que vous aimez </h3>
                            <p className="mt-1   font-light text-[#141943]">Ce sont les activités, les intérêts ou les hobbies qui vous passionnent et vous apportent de la joie.</p>
                        </div>

                        <div className=" p-2 rounded-lg shadow-lg border-1 p-4">
                            <img src={Tool} alt="Description of image" className="p-1" />
                            <h3 className="text-md  mt-2 font-semibold font-poppins text-[#141943] md:min-h-[3rem] overflow-hidden " >Ce dans quoi vous êtes doué(e)  </h3>
                            <p className="mt-1   font-light text-[#141943]">Ce sont vos compétences, vos talents naturels ou acquises, et les activités dans lesquelles vous excellez.</p>
                        </div>

                        <div className=" p-2 rounded-lg shadow-lg border-1 p-4">
                            <img src={Charity} alt="Description of image" className="p-1" />
                            <h3 className="text-md mt-2 font-semibold font-poppins text-[#141943] md:min-h-[3rem] overflow-hidden " >Ce dont le monde a besoin </h3>
                            <p className="mt-1   font-light text-[#141943]">Il s'agit des besoins de la société ou de votre communauté auxquels vous pouvez contribuer positivement.</p>
                        </div>

                        <div className=" p-2 rounded-lg shadow-lg border-1 p-4">
                            <img src={Money} alt="Description of image" className="p-1" />
                            <h3 className="text-md font-semibold mt-2 font-poppins text-[#141943] md:min-h-[3rem] overflow-hidden " > Ce pour quoi vous pouvez être rémunéré(e) </h3>
                            <p className="mt-1   font-light text-[#141943]">Ce sont les activités ou les métiers pour lesquels vous pouvez être rémunéré(e) et qui vous permettent de subvenir à vos besoins financiers.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" pt-5 pb-2 rounded-md border-1 shadow-lg mx-3 mt-2 mb-2">
                <div className=" px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
                        <div className="p-4">
                            <div>
                                <h2 className="text-2xl font-bold font-poppins text-[#141943] mb-4">L’IKIGAI : une vie pleine de sens et d'harmonie</h2>
                                <p className="text-[#141943] ">L'ikigai se situe à l'intersection de ces quatre éléments.</p>
                                <p className="text-[#141943] ">Lorsque vous trouvez cette intersection, vous êtes en harmonie avec vous-même et avec le monde qui vous entoure, ce qui peut conduire à une vie pleine de sens, de satisfaction et de bonheur.</p>
                                <hr className="h-1 py-2 text-black"/>
                                <h2 className="text-2xl font-bold font-poppins text-[#141943] mb-4">Le test : Lien avec l'IKIGAI</h2>
                                <p className="text-[#141943] ">Le test d'orientation se compose de deux sections permettant de mieux cerner votre environnement de travail idéal, votre personnalité et vos sujets préférés.</p>
                                <p className="text-[#141943] ">En analysant vos réponses, l’algorithme identifie pour vous les métiers qui vous correspondent le plus et qui répondent à un besoin réel.</p>
                                <p className="text-[#141943] "> Passez le test pour augmenter vos chances de trouver votre voie ;)</p>
                            </div>
                        </div>
                        <div className="flex justify-center item center ">
                            <img src={Igikai} alt="Description of image" className="object-cover " />
                        </div>
                    </div>
                </div>
                <div className="flex lg:hidden pt-4 px-3 gap-2 justify-center  ">
                    <Button type="primary" className="font-semibold text-md gap-3 w-full "
                            onClick={handleTestButtonClick}
                    >
                        {viewResult ? 'Repassez le test ' : 'Passez le test' }
                        <Handicon />

                    </Button>

                    {viewResult && (
                        <Button onClick={handleResultsButtonClick} className="font-semibold text-md gap-3 w-full ">
                            Voir mes résultats
                        </Button>
                    )}
                </div>
            </div>

        </LayoutNo>
    );
}