export const parseUserAgent = () => {
    const userAgent = navigator.userAgent;
    let browser = "Unknown";
    let os = "Unknown";
    let device_type = "Unknown";

    if (userAgent.includes("Firefox")) {
        browser = "Firefox";
    } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
        browser = "Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        browser = "Safari";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        browser = "Internet Explorer";
    } else if (userAgent.includes("Edg")) {
        browser = "Edge";
    }

    if (userAgent.includes("Windows NT")) {
        os = "Windows";
    } else if (userAgent.includes("Mac OS X")) {
        os = "MacOS";
    } else if (userAgent.includes("Linux")) {
        os = "Linux";
    } else if (userAgent.includes("Android")) {
        os = "Android";
    } else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
        os = "iOS";
    }

    device_type = /Mobi|Android/i.test(userAgent) ? "Mobile" : "Desktop";

    return { browser, os, device_type };
};
