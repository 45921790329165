import React, {useEffect, useState} from 'react';
import { useNavigate} from 'react-router-dom';
import {Button, Popconfirm} from "antd";
import {CheckOutlined, CloseOutlined, DeleteOutlined} from "@ant-design/icons";
import {CryptoService} from "../services/crypte.service";

const SavedFormationCard = ({savedItems = [], loading = false, onDelete,}) => {
    const navigate = useNavigate();

    const handleClick = (data) => {
        let schoolName =  CryptoService.nameUrlFormatVF(data.nom_etab)
        let formationName = CryptoService.nameUrlFormatVF(data.nom_formation)
        navigate(`/formation/${schoolName}/${formationName}/${data.postbac_id}`)
    };

    const renderSkeleton = () => (
        <div className="bg-gray-100 border rounded-lg shadow-md p-4 animate-pulse">
            <div className="flex justify-between items-center mb-2">
                <div className="flex w-full">
                    <div className="w-14 h-14 bg-gray-200 rounded-xl mr-3"></div>
                    <div>
                        <div className="h-4 bg-gray-200 rounded w-24 mb-2"></div>
                        <div className="h-4 bg-gray-200 rounded w-32"></div>
                        <div className="h-4 bg-gray-200 rounded w-32"></div>
                    </div>
                </div>
            </div>
            <div className="h-4 bg-gray-200 rounded w-40 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-52 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-full "></div>

        </div>
    );

    return (



    <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  py-2 ">

        {loading ? (
            Array.from({ length: 4 }).map((_, index) => (
                <React.Fragment key={index}>{renderSkeleton()}</React.Fragment>

            ))
        ) : savedItems.length > 0 ? (
            savedItems.map((data) => (
                <div className="bg-white border-1 hover:border-blue-500 rounded-lg shadow-md p-2 relative">
                    <div className="flex justify-between items-center mb-2 relative">
                        <div  className="flex justify-between items-center w-full">
                            <div className="flex justify-start cursor-pointer " onClick={() => handleClick(data)}>
                                <img src={data.logo} alt="logo" className="w-14 h-14 object-cover border-2 rounded-xl flex-shrink-0 mr-1" />
                                <div>
                                    <p
                                        className="font-inter mt-3 text-[15px] text-[#141943] font-inter line-clamp-2 min-h-[36px] truncate-text"
                                        title={data.nom_etab}
                                    >
                                        {data.nom_etab}
                                    </p>
                                </div>

                            </div>

                            <div className=" flex justify-end -mt-6">
                                <Popconfirm
                                    title="Etes-vous sûr de vouloir supprimer cet formation ?"
                                    onConfirm={() => onDelete(data.postbac_id)}
                                    okText={<CheckOutlined />}
                                    cancelText={<CloseOutlined />}
                                    placement="topRight"
                                >
                                    <Button
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        size="small"
                                        type="text"
                                        className="w-[18px] h-[18px]  opacity-100"
                                    />
                                </Popconfirm>
                            </div>

                        </div>

                    </div>
                    <h3 className="font-semibold mt-4  text-[16px] text-[#141943] font-inter  line-clamp-2 min-h-[38px]" title={data.nom_formation}>{data.nom_formation}</h3>
                    <hr className="my-2" />
                    <div className="flex justify-between items-start mt-2" onClick={() => handleClick(data)}>
                        <div className="flex flex-wrap gap-2 mt-1">
                            {/* Niveau Chip */}
                            <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                            <path d="M0.5 6L8 2L15.5 6L8 10L0.5 6Z" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.75 15V8L8 6" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.75 6.93359V10.3411C13.7502 10.449 13.7153 10.554 13.6507 10.6403C13.2296 11.2006 11.4533 13.2503 8 13.2503C4.54669 13.2503 2.77039 11.2006 2.34935 10.6403C2.28466 10.554 2.24979 10.449 2.25 10.3411V6.93359" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                                {data.niveau}
                    </span>

                            {/* Region Chip */}
                            <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                            <path d="M8 8.5C9.10457 8.5 10 7.60457 10 6.5C10 5.39543 9.10457 4.5 8 4.5C6.89543 4.5 6 5.39543 6 6.5C6 7.60457 6.89543 8.5 8 8.5Z" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13 6.5C13 11 8 14.5 8 14.5C8 14.5 3 11 3 6.5C3 5.17392 3.52678 3.90215 4.46447 2.96447C5.40215 2.02678 6.67392 1.5 8 1.5C9.32608 1.5 10.5979 2.02678 11.5355 2.96447C12.4732 3.90215 13 5.17392 13 6.5V6.5Z" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                                {data.commune}
                    </span>


                            {/* Code Postal Chip */}
                    {/*        <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">*/}
                    {/*    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">*/}
                    {/*        <path d="M14 3.5L8 9L2 3.5" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />*/}
                    {/*        <path d="M2 3.5H14V12C14 12.1326 13.9473 12.2598 13.8536 12.3536C13.7598 12.4473 13.6326 12.5 13.5 12.5H2.5C2.36739 12.5 2.24021 12.4473 2.14645 12.3536C2.05268 12.2598 2 12.1326 2 12V3.5Z" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />*/}
                    {/*        <path d="M6.90921 8L2.1543 12.3587" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />*/}
                    {/*        <path d="M13.8468 12.3587L9.0918 8" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />*/}
                    {/*    </svg>*/}
                    {/*            {data.code_postal?.split('.')[0] || "non disponible"}*/}
                    {/*</span>*/}
                            {data.taux_dacces &&(
                                <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M8.73433 11.5156C8.15618 10.0735 8.09367 8.47599 8.55738 6.99314C9.02108 5.51028 9.98256 4.23301 11.2793 3.37721C12.576 2.52142 14.1285 2.1396 15.6742 2.2963C17.22 2.45299 18.6642 3.13859 19.7628 4.2372C20.8614 5.33582 21.547 6.78006 21.7037 8.3258C21.8604 9.87155 21.4786 11.424 20.6228 12.7207C19.767 14.0175 18.4897 14.9789 17.0069 15.4426C15.524 15.9063 13.9265 15.8438 12.4844 15.2657L12.4845 15.2655L11.25 16.5H9V18.75H6.75V21H3V17.25L8.7345 11.5155L8.73433 11.5156Z" stroke="#434769" stroke-linecap="round" stroke-linejoin="round"/>
                            <path opacity="0.5" d="M16.875 7.5C17.0821 7.5 17.25 7.33211 17.25 7.125C17.25 6.91789 17.0821 6.75 16.875 6.75C16.6679 6.75 16.5 6.91789 16.5 7.125C16.5 7.33211 16.6679 7.5 16.875 7.5Z" stroke="#434769" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.875 7.875C17.2892 7.875 17.625 7.53921 17.625 7.125C17.625 6.71079 17.2892 6.375 16.875 6.375C16.4608 6.375 16.125 6.71079 16.125 7.125C16.125 7.53921 16.4608 7.875 16.875 7.875Z" fill="#434769"/>
                        </svg>
                                    {data.taux_dacces} %

                    </span>)}

                            {/* Alternance Chip */}
                            {data.apprentissage === "1" || "2"  &&(
                                <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                            <path d="M13.5 4.5H2.5C2.22386 4.5 2 4.72386 2 5V13C2 13.2761 2.22386 13.5 2.5 13.5H13.5C13.7761 13.5 14 13.2761 14 13V5C14 4.72386 13.7761 4.5 13.5 4.5Z" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.5 4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2C8.66304 2 9.29893 2.26339 9.76777 2.73223C10.2366 3.20107 10.5 3.83696 10.5 4.5" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                            Alternance
                    </span>
                            )}

                            {data.amenagement === "À distance" &&(
                                <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 20.2504V4.50043" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M3.75 15.7505C9.75 11.2505 14.25 20.2505 20.25 15.7505V4.50045C14.25 9.00045 9.75 0.000454098 3.75 4.50045" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            À distance
                    </span>
                            )}

                        </div>
                    </div>
                </div>
            ))
        ) : (
            <div className=" p-1   text-center ">
                <p className="text-lg text-gray-500">Aucune formation enregistrée</p>
            </div>
        )}
    </div>
    );
}

export default SavedFormationCard;
