import { useEffect, useState } from "react";

const CookieConsent = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        // Function to check if the consent cookie exists
        const hasConsentCookie = () => {
            return document.cookie.split("; ").some((row) => row.startsWith("axeptio_cookies="));
        };

        // Function to set the consent cookie
        const setConsentCookie = (value) => {
            const expiryDate = new Date();
            expiryDate.setFullYear(expiryDate.getFullYear() + 1); // Set expiry for 1 year
            document.cookie = `axeptio_cookies=${JSON.stringify(value)}; expires=${expiryDate.toUTCString()}; path=/;`;
        };

        // Function to initialize Axeptio
        const initializeAxeptio = () => {
            // Define the Axeptio settings
            window.axeptioSettings = {
                clientId: "67376952c571122b4ca98e05",
                cookiesVersion: "linkyways-fr-EU",
                googleConsentMode: {
                    default: {
                        analytics_storage: "denied",
                        ad_storage: "denied",
                        ad_user_data: "denied",
                        ad_personalization: "denied",
                        wait_for_update: 500,
                    },
                },
                onComplete: (choices) => {
                    console.log("User made a choice:", choices);
                    // Save the user's choice
                    setConsentCookie({
                        analytics: choices.analytics || false,
                        ads: choices.ads || false,
                        completed: true,
                    });
                    // Hide the banner immediately after consent
                    setShowBanner(false);

                    // Update Google Consent Mode for GTM
                    if (window.gtag) {
                        window.gtag("consent", "update", {
                            analytics_storage: choices.analytics ? "granted" : "denied",
                            ad_storage: choices.ads ? "granted" : "denied",
                        });
                    }
                },
            };

            // Inject the Axeptio script dynamically
            const script = document.createElement("script");
            script.src = "//static.axept.io/sdk.js";
            script.async = true;

            // Initialize Axeptio SDK when the script is loaded
            script.onload = () => {
                console.log("Axeptio script loaded successfully.");
                // Show the banner if no consent cookie exists
                if (!hasConsentCookie()) {
                    setShowBanner(true);
                    window.Axeptio && window.Axeptio.showConsentBanner();
                }
            };

            script.onerror = () => {
                console.error("Failed to load Axeptio script.");
            };

            // Append the script to the document
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        };

        if (!hasConsentCookie()) {
            initializeAxeptio();
        }
    }, []); // No dependency array as no external state or props are used

    if (!showBanner) {
        return null; // Don't render anything if the banner shouldn't be shown
    }

    return null; // No explicit UI as Axeptio manages the banner
};

export default CookieConsent;





//
//
// import { useEffect } from "react";
//
// const CookieConsent = () => {
//     useEffect(() => {
//         // Axeptio Settings
//         window.axeptioSettings = {
//             clientId: "67376952c571122b4ca98e05", // Replace with your client ID
//             cookiesVersion: "linkyways-fr-EU",
//             googleConsentMode: {
//                 default: {
//                     analytics_storage: "denied",
//                     ad_storage: "denied",
//                     ad_user_data: "denied",
//                     ad_personalization: "denied",
//                     wait_for_update: 500,
//                 },
//             },
//         };
//
//         // Add Axeptio SDK dynamically
//         const axeptioScript = document.createElement("script");
//         axeptioScript.src = "//static.axept.io/sdk.js";
//         axeptioScript.async = true;
//         document.body.appendChild(axeptioScript);
//
//         // Function to load Facebook Pixel
//         const loadFacebookPixelTag = () => {
//             if (!window.fbq) {
//                 (function (f, b, e, v, n, t, s) {
//                     if (f.fbq) return;
//                     n = f.fbq = function () {
//                         n.callMethod
//                             ? n.callMethod.apply(n, arguments)
//                             : n.queue.push(arguments);
//                     };
//                     if (!f._fbq) f._fbq = n;
//                     n.push = n;
//                     n.loaded = true;
//                     n.version = "2.0";
//                     n.queue = [];
//                     t = b.createElement(e);
//                     t.async = true;
//                     t.src = v;
//                     s = b.getElementsByTagName(e)[0];
//                     s.parentNode.insertBefore(t, s);
//                 })(
//                     window,
//                     document,
//                     "script",
//                     "https://connect.facebook.net/en_US/fbevents.js"
//                 );
//
//                 // fbq("init", "000000000000000"); // Replace with your Pixel ID
//                 // fbq("track", "PageView");
//             }
//         };
//
//         // Listen for Axeptio Consent Event
//         window._axcb = window._axcb || [];
//         window._axcb.push((axeptio) => {
//             axeptio.on("cookies:complete", (choices) => {
//                 if (choices.facebook_pixel) {
//                     loadFacebookPixelTag();
//                 }
//             });
//         });
//
//         // Cleanup script on component unmount
//         return () => {
//             document.body.removeChild(axeptioScript);
//         };
//     }, []);
//
//     return null;
// };
//
// export default CookieConsent;
