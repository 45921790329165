import React, { useState, useRef, useEffect } from 'react';
import ArrowDown from '../../images/arrowDown';
import {useAuth} from "../../services/auth.provider"; // Replace with your actual path

const MultiCustomSelect = ({
                               options,
                               onChange,
                               optionKey,
                               optionLabel,
                               placeholder,
                               initialSelectedOptions,
                               clearFilters,
                               width,
                           }) => {
    const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions || []);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownButtonRef = useRef(null);
    const dropdownMenuRef = useRef(null);
    const { isAuthenticated } = useAuth();

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const handleOptionClick = (option) => {
        const isSelected = selectedOptions.some((selected) => selected[optionKey] === option[optionKey]);
        let updatedOptions;

        if (isSelected) {
            updatedOptions = selectedOptions.filter((selected) => selected[optionKey] !== option[optionKey]);
        } else {
            updatedOptions = [...selectedOptions, option];
        }

        setSelectedOptions(updatedOptions);
        onChange && onChange(updatedOptions);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownButtonRef.current &&
            !dropdownButtonRef.current.contains(event.target) &&
            dropdownMenuRef.current &&
            !dropdownMenuRef.current.contains(event.target)
        ) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (clearFilters) {
            setSelectedOptions([]);
            onChange && onChange([]);
        }
    }, [clearFilters, onChange]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Update selectedOptions when initialSelectedOptions change (e.g., on refresh)
        setSelectedOptions(initialSelectedOptions || []);
    }, [initialSelectedOptions]);

    return (
        <div className="relative">
            <button
                className="flex justify-between items-center w-full px-4 py-2.5 border border-[#D0DBE7] bg-white rounded-lg focus:outline-none"
                onClick={toggleDropdown}
                ref={dropdownButtonRef}
            >
                <span className="flex items-center" style={{ width: width || '150px' }}>
                    {placeholder}
                    {selectedOptions.length > 0 && (
                        <span className="ml-2 flex items-center">
                            <span
                                className="ml-2 text-xs font-bold text-white bg-black rounded-full p-1 w-12 h-4 flex items-center justify-center"
                            >
                                {selectedOptions.length}
                            </span>
                        </span>
                    )}
                </span>
                <span className="ml-2"><ArrowDown /></span>
            </button>
            {isDropdownOpen && isAuthenticated &&(
                <div
                    ref={dropdownMenuRef}
                    className="custom-dropdown absolute left-0 mt-2 w-full bg-white border border-[#D0DBE7] rounded-lg shadow-lg z-20"
                >
                    <ul className="py-2">
                        {options.map((option) => (
                            <li
                                key={option[optionKey]}
                                className={`flex items-center p-2 hover:bg-gray-100 ${selectedOptions.some((selected) => selected[optionKey] === option[optionKey]) ? 'bg-blue-50' : ''}`}
                            >
                                <label className="flex items-center w-full cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions.some((selected) => selected[optionKey] === option[optionKey])}
                                        onChange={() => handleOptionClick(option)}
                                        className=" hidden"
                                    />
                                    {selectedOptions.some((selected) => selected[optionKey] === option[optionKey]) ? (
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 min-w-4">
                                            <rect y="0.5" width="16" height="16" rx="3" fill="#141943" />
                                            <path d="M11.4375 6.625L6.85414 11L4.5625 8.8125" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 min-w-4 ">
                                            <rect x="0.5" y="1" width="15" height="15" rx="2.5" stroke="#141943" />
                                        </svg>
                                    )}
                                    <span className="text-sm text-gray-800">
                                        {option[optionLabel]}
                                    </span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MultiCustomSelect;
