import React, { useState } from 'react';

const RatingComponent = ({
    ratingValue,
    onRatingChange,
    maxRating = 3,
    starColor = 'text-yellow-400',
    inactiveStarColor = 'text-gray-300',
    size = 'w-10 h-10'
}) => {
    const [hoverValue, setHoverValue] = useState(0);

    const handleMouseEnter = (value) => {
        setHoverValue(value);
    };

    const handleMouseLeave = () => {
        setHoverValue(0);
    };

    const handleClick = (value) => {
        onRatingChange(value);
        setHoverValue(0);
    };

    return (
        <div className="flex items-center justify-center">
            {Array.from({ length: maxRating }, (_, index) => (
                <svg
                    key={index}
                    onClick={() => handleClick(index + 1)}
                    onMouseEnter={() => handleMouseEnter(index + 1)}
                    onMouseLeave={handleMouseLeave}
                    className={`${size} cursor-pointer ${index < (hoverValue || ratingValue) ? starColor : inactiveStarColor}`}
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ margin: '0 0.5rem', padding: 0 }} // Ici, vous ajoutez un margin horizontal de 0.5rem
                >
                    <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21z" />
                </svg>
            ))}
        </div>

    );
};

export default RatingComponent;
