import { useAxios } from './caller.service';

export const useItemsService = () => {
    const AxiosInstance = useAxios();

    let getSavedPostbac = (userId) => {
        if (!userId) {
            return Promise.reject('User ID is required');
        }
        return AxiosInstance.get(`/get_saved_postbac/${userId}`);
    }

    let savePostbac = async (userId, postbacId) => {
        try {
            const requestBody = {
                "user_id": userId,
                "postbac_id": postbacId
            };

            const response = await AxiosInstance.post('/save_postbac/', requestBody);
            return response.data;

        } catch (error) {
            console.error('Error saving postbac data:', error);
            throw error;
        }
    };

    let deletePostbac = async (userId, postbacId) => {
        try {
            const params = { postbac_id: postbacId };
            // console.log("Deleting postbac with params:", params);

            const response = await AxiosInstance.delete(`/delete_postbac/${userId}`, { params });
            return response.data;

        } catch (error) {
            console.error('Error deleting postbac data:', error);
            throw error;
        }
    };

    // ********************************************** MASTER **********************************************

    let getSavedMaster = (userId) => {
        if (!userId) {
            return Promise.reject('User ID is required');
        }
        // console.log("Fetching saved master for user_id:", userId);
        return AxiosInstance.get(`/get_saved_master/${userId}`);
    }

    let saveMaster = async (userId, masterId) => {
        try {
            const requestBody = {
                "user_id": userId,
                "master_id": masterId
            };
            // console.log("Saving master with requestBody:", requestBody);

            const response = await AxiosInstance.post('/save_master/', requestBody);
            return response.data;

        } catch (error) {
            console.error('Error saving master data:', error);
            throw error;
        }
    };

    let deleteMaster = async (userId, masterId) => {
        try {
            const params = { master_id: masterId };
            // console.log("Deleting master with params:", params);

            const response = await AxiosInstance.delete(`/delete_master/${userId}`, { params });
            return response.data;

        } catch (error) {
            console.error('Error deleting master data:', error);
            throw error;
        }
    };

    // ********************************************** Professions **********************************************

    let getSavedProfessions = (userId) => {
        if (!userId) {
            return Promise.reject('User ID is required');
        }
        return AxiosInstance.get('/get_saved_professions/', {
            params: { user_id: userId }
        });
    };


    let saveProfession = async (userId, professionId) => {
        try {
            const requestBody = {
                "user_id": userId,
                "profession_id": professionId
            };

            const response = await AxiosInstance.post('/save_profession/', requestBody);
            return response.data;

        } catch (error) {
            console.error('Error saving profession data:', error);
            throw error;
        }
    };

    let deleteProfession = async (userId, professionId) => {
        try {
            const params = { profession_id: professionId };

            const response = await AxiosInstance.delete(`/delete_profession/${userId}`, { params });
            return response.data;

        } catch (error) {
            console.error('Error deleting profession data:', error);
            throw error;
        }
    };

    return {
        getSavedPostbac,
        savePostbac,
        deletePostbac,
        getSavedMaster,
        saveMaster,
        deleteMaster,
        getSavedProfessions,
        saveProfession,
        deleteProfession
    }
}
