import { useAxios } from './caller.service';


export const useQuestionsService = () => {
    const AxiosInstance = useAxios(); 

    let getQuestions = (question_search_by_page = null) => {
        let params = {};

        if (question_search_by_page !== null) {
            params.question_search_by_page = question_search_by_page;
        }

        return AxiosInstance.get('/IKI_questions/', { params: params });
    };

    let addQuestions = (userId, responses, subjectsLevel) => {
        const requestBody = {
            user_id: userId,
            questions_scores: responses.map(response => ({
                question_id: response.questionId,
                score: response.score
            })),
            subjects_level: subjectsLevel
        };

        return AxiosInstance.post('/UserSubmission/', requestBody)
            .then(response => {
                
            })
            .catch(error => {
                console.error('Error submitting data:', error);
            });
    };

    return {
        getQuestions, addQuestions
    };
};
