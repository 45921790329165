const ThirdSideIcon =()=>{
    return(
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M29.5 59C45.7924 59 59 45.7924 59 29.5C59 13.2076 45.7924 0 29.5 0C13.2076 0 0 13.2076 0 29.5C0 45.7924 13.2076 59 29.5 59Z" fill="#F7F8FA"/>
            <path d="M29 37C33.4183 37 37 33.4183 37 29C37 24.5817 33.4183 21 29 21C24.5817 21 21 24.5817 21 29C21 33.4183 24.5817 37 29 37Z" stroke="#0F53FA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M35 35L40 40" stroke="#0F53FA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    )
}
export default ThirdSideIcon;