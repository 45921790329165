import React from 'react';
import checkedIcon from '../../../images/cards/checked.svg';
import unchecked from '../../../images/cards/unchecked.svg';

export default function CustomMultiRadio({ id, value, label, checked, onChange }) {
    return (
        // <label
        //     className={`flex items-center py-3 px-4 w-full mt-1 gap-2.5 rounded-md text-gray-800 text-xs shadow-xs cursor-pointer mr-4 ${checked ? 'bg-[#FAF2D3] border-1 border-black' : 'bg-white border border-gray-400'}`}
        // >
        //     <input
        //         type="checkbox"
        //         id={id}
        //         value={value}
        //         checked={checked}
        //         onChange={onChange}
        //         className="hidden"
        //     />
        //     <span className="flex-shrink-0">
        //         {checked ? (
        //             <img src={checkedIcon} alt="Checked Icon" className="w-8 h-8" />
        //         ) : (
        //             <img src={unchecked} alt="Unchecked Icon" className="w-8 h-8" />
        //         )}
        //     </span>
        //     <span className="font-inter text-[16px]">{label}</span>
        // </label>
        <div className="flex gap-x-4">
            <label
                className={`flex items-center py-3 px-4 w-full mt-1 gap-2.5 rounded-md text-gray-800 text-xs shadow-xs cursor-pointer mr-4 mb-2 ${checked ? 'bg-[#FAF2D3] border-1 border-black' : 'bg-white border border-gray-400'}`}
            >
                <input
                    type="checkbox"
                    id={id}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    className="hidden"
                />
                <span className="flex-shrink-0">
                    {checked ? (
                        <img src={checkedIcon} alt="Checked Icon" className="w-8 h-8" />
                    ) : (
                        <img src={unchecked} alt="Unchecked Icon" className="w-8 h-8" />
                    )}
                </span>
                <span className="font-inter text-[16px] text-[#141943]">{label}</span>
            </label>
        </div>
    );
}

