import React from 'react';
import checkedIcon from '../images/cards/checked.svg';
import unchecked from '../images/cards/unchecked.svg';

export default function CustomRadio({ id, value, label, checked, onChange }) {
    return (
        <label
            className={`flex items-center py-2.5 px-4 w-full mt-1.5 gap-3 rounded-md text-gray-800 text-xs shadow-xs cursor-pointer ${checked ? 'bg-[#FAF2D3] border-black border-1' : 'bg-white border border-[#D0DBE7]'} `}
        >
            <input
                type="radio"
                id={id}
                value={value}
                checked={checked}
                onChange={onChange}
                className="hidden"
            />
            <span className="flex-shrink-0">
                {checked ? (
                    <img src={checkedIcon} alt="Checked Icon" className="w-8 h-8" />
                ) : (
                    <img src={unchecked} alt="Checked Icon" className="w-8 h-8" />
                )}
            </span>
            <span className="font-inter text-[16px] text-[#141943]">{label}</span>
        </label>
    );
}


// import React from 'react';

// export default function CustomRadio({ id, value, label, checked, onChange }) {
//     return (
//         <label
//             className={`flex items-center py-4 px-4 w-full mt-2 gap-2.5 rounded-md border text-gray-800 text-xs shadow-xs cursor-pointer ${checked ? 'bg-[#FAF2D3] border-black' : 'bg-white border-gray-400'}`}
//         >
//             <input
//                 type="radio"
//                 id={id}
//                 value={value}
//                 checked={checked}
//                 onChange={onChange}
//                 className="hidden"
//             />
//             <span className="flex-shrink-0">
//                 <svg
//                     className={`w-13 h-5 rounded-full ring-4 ${checked ? 'ring-black bg-black border-black' : 'ring-gray-50 bg-white border-gray-400'}`}
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     stroke="currentColor"
//                     strokeWidth="2"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                 >
//                     {checked && (
//                         <circle fill="currentColor" />
//                     )}
//                 </svg>
//             </span>
//             <span className="font-inter text-[16px]">{label}</span>
//         </label>
//     );
// }
