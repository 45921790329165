import React, { useState, useRef, useEffect } from "react";
import ArrowDown from "../../images/arrowDown";

const CustomSelect = ({ options, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const allOptions = [...options];

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleOptionClick = (option) => {
    setSelectedOptions((prevSelected) => {
      const isSelected = prevSelected.some((o) => o.cat_id === option.cat_id);
      let newSelected = [];

      if (isSelected) {
        newSelected = prevSelected.filter((o) => o.cat_id !== option.cat_id);
      } else {
        newSelected = [...prevSelected, { cat_id: option.cat_id, cat_name: option.cat_name }];
      }

      // Add logging to check selected options
      // console.log("Selected Options after click:", newSelected);

      if (onChange) onChange(newSelected);
      return newSelected;
    });
  };

  const handleClickOutside = (event) => {
    if (
      dropdownButtonRef.current &&
      !dropdownButtonRef.current.contains(event.target) &&
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClearAll = () => {
    setSelectedOptions([]);
    if (onChange) onChange([]);
    setIsDropdownOpen(false);
  };

  const customFooter = (
    <div className="flex justify-between p-2 border-t border-gray-200">
      <button
        onClick={handleClearAll}
        className="font-inter text-gray-600 ml-2 hover:text-blue-600"
      >
        Tout effacer
      </button>
    </div>
  );

  return (
    <div className="relative">
      <button
        className="flex justify-between items-center w-full px-4 py-2.5 border border-[#D0DBE7] rounded-lg focus:outline-none"
        onClick={toggleDropdown}
        ref={dropdownButtonRef}
      >
        Catégories {selectedOptions.length > 0 && `(${selectedOptions.length})`}
        <ArrowDown />
      </button>
      {isDropdownOpen && (
        <div
          ref={dropdownMenuRef}
          className="absolute right-0 mt-2 w-[300px] bg-white border border-[#D0DBE7] rounded-lg shadow-lg z-10"
        >
          <ul className="max-h-60 overflow-y-auto py-2">
            {allOptions.map((option) => (
              <li
                key={option.cat_id}
                className={`flex items-center p-2 hover:bg-gray-100 ${
                  selectedOptions.some((o) => o.cat_id === option.cat_id) ? "bg-blue-50" : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={selectedOptions.some((o) => o.cat_id === option.cat_id)}
                  onChange={() => handleOptionClick(option)}
                  className="form-checkbox rounded mr-2"
                />
                <span className="text-sm text-gray-800">{option.cat_name}</span>
              </li>
            ))}
          </ul>
          {customFooter}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
