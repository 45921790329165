import React, {useEffect, useState} from "react";
import RedHeartIcon from "../images/heart-filled.svg";
import WhiteHeartIcon from "../images/whiteheart.svg";
import {useItemsService} from "../services/ items.service";
import {notification} from "antd";
import { CryptoService } from "../services/crypte.service";
import {useNavigate} from "react-router-dom";

const TabContent = ({ userId, metiers }) => {
    const [data, setData] = useState([]);
    const {deleteProfession, saveProfession} = useItemsService();
    const navigate = useNavigate();

    useEffect(() => {
        const sortedMetiers = metiers.sort((a, b) => b.profession_score - a.profession_score)
            .map(item => ({
                ...item,
                correspondance: item.profession_score >= 85 ? "Très Élevée"
                    : item.profession_score >= 60 ? "Élevée"
                        : "Modérée"
            }));
        setData(sortedMetiers);
    }, [userId, metiers]);
    
    const toggleSaveProfession = async (item) => {
        try {
            if (item.saved === 1) {
                await deleteProfession(userId, item.profession_id);
                item.saved = 0;
            } else {
                await saveProfession(userId, item.profession_id);
                item.saved = 1;
                notification.success({
                    message: 'Enregistré',
                    placement: 'topRight',
                });
            }
            setData([...data]);
        } catch (error) {
            console.error('Error toggling save status:', error);
        }
    };

    const goToNewPage = (professionId, name) => () => {
        const professionName = CryptoService.nameUrlFormat(name.toString())
        navigate(`/metiers/${professionName}/${professionId}`);
    };

    return (
        <div >
            {Array.isArray(metiers) && metiers?.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2 px-2 mt-1  ">
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className="border-1 group border-gray-300 bg-white p-4 hover:border-blue-500 rounded-lg shadow-md "
                        >
                            <div>
                                <div className="flex justify-between items-center mb-2">
                                    <div className="flex items-center">
                                        <img
                                            src={item.pictact}
                                            alt=" pic"
                                            className="max-w-[2rem] mr-2"
                                        />
                                        <div
                                            onClick={goToNewPage(item.profession_id, item.profession)}
                                            className="font-inter font-medium text-[14px] text-[#25283d] hover:text-blue-500  cursor-pointer"
                                        >
                                            {item.business_sect}
                                        </div>
                                    </div>
                                    <button
                                        className="flex items-center justify-center"
                                        onClick={() => toggleSaveProfession(item)}
                                    >
                                        <img
                                            src={item.saved === 1 ? RedHeartIcon : WhiteHeartIcon}
                                            alt="Love Icon"
                                        />
                                    </button>
                                </div>

                                <div onClick={goToNewPage(item.profession_id, item.profession)} className="cursor-pointer ">
                                    <hr className="border-t-0 border-gray-300 my-3" />
                                    <div
                                        onClick={goToNewPage(item.profession_id, item.profession)}
                                        className="font-inter font-semibold text-lg text-[#141943] hover:text-blue-500 min-h-[2rem] cursor-pointer mb-2 "
                                    >
                                        {item.profession}
                                    </div>

                                    {/*<div className="flex flex-col space-y-1">*/}
                                    {/*    <div className="flex justify-between items-center">*/}
                                    {/*        <div className="min-w-[4rem] text-base text-[#141943]">Score :</div>*/}
                                    {/*        <div className="font-inter font-semibold text-[14px] text-[#141943]">*/}
                                    {/*            {item.profession_score} %*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="flex flex-col space-y-1">
                                        {/*<div className="flex justify-between items-center">*/}
                                        {/*    <div className="min-w-[4rem] text-base text-[#141943]">Secteur :</div>*/}
                                        {/*    <div className="font-inter font-medium text-[14px] text-[#141943]">*/}
                                        {/*        {item.business_sect}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="flex  items-center">
                                            <div className="min-w-[4rem] text-base text-[#141943]">Score :</div>
                                            {/*<div className="font-inter font-bold text-[14px]">*/}
                                                <span
                                                    className="py-1 px-2 text-md font-bold rounded-full text-[#2D7A07]"
                                                >
                                                    {item.profession_score} %
                                                </span>
                                            {/*</div>*/}
                                        </div>
                                    </div>

                                    <div className="flex flex-row mt-2">
                                        <div className=" text-base text-[#141943] mr-4">Skills:</div>

                                        <div className="flex justify-between items-start -mt-1 ">
                                            <div className="flex flex-wrap gap-1 mt-1">
                                                {item.skills?.split(',').map((comp, compIndex) => (
                                                    <span
                                                        key={compIndex}
                                                        className="py-1 px-2  text-xs font-semibold bg-[#EAEFF4] text-[#141943] rounded-full"
                                                    >{comp.trim()}
                                                    </span>
                                                ))}
                                            </div>

                                            {/*<button*/}
                                            {/*    className="rounded-full hover:bg-blue-500 flex-shrink-0"*/}
                                            {/*    onClick={goToNewPage(item.profession_id)}*/}
                                            {/*>*/}
                                            {/*    <RightCircleOutlined className="text-blue-500 hover:text-white text-xl p-1" />*/}
                                            {/*</button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 px-2 mt-1">
                    {Array.from({ length: 6 }).map((_, index) => (
                        <div key={index} className="border-1 border-gray-300 bg-white p-4 rounded-lg mb-2 animate-pulse">
                            <div className="flex justify-between items-center mb-2">
                                <div className="flex items-center">
                                    <div className="h-8 w-8 bg-gray-200 rounded-md mr-2"></div>
                                    <div className="h-4 bg-gray-200 rounded w-32"></div>
                                </div>
                                {/*<div className="h-5 w-5 bg-gray-200 rounded-full"></div>*/}
                            </div>
                            <hr className="border-t-2 border-gray-200 mb-2" />
                            <div className="flex flex-col space-y-2">
                                <div className=" items-center ">
                                    <div className="h-4 bg-gray-200 rounded w-20"></div>
                                    <div className="h-4 bg-gray-200 rounded w-16 mt-2"></div>
                                </div>
                                <div className="items-center">
                                    <div className="h-4 bg-gray-200 rounded w-full"></div>
                                    <div className="h-4 bg-gray-200 rounded w-full mt-2"></div>
                                </div>
                            </div>
                            <div className="flex flex-col mt-2">
                                <div className="h-4 bg-gray-200 rounded w-20"></div>
                                <div className="flex justify-between items-start mt-2">
                                    <div className="flex gap-2 mt-1">
                                        <div className="h-4 bg-gray-200 rounded-full w-12"></div>
                                        <div className="h-4 bg-gray-200 rounded-full w-12"></div>
                                        <div className="h-4 bg-gray-200 rounded-full w-12"></div>

                                    </div>
                                    <div className="h-8 w-full bg-gray-200 rounded-full"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>


    );
};

export default TabContent;
