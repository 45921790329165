import React from 'react';
import { Carousel } from 'primereact/carousel';

import Partner7 from "../images/Linkywayslogo.svg";
import Partner8 from "../images/logo_ft_paris-saclay_couleur_vectoriel.png"

const PartnerCarousel = ({ autoplayInterval }) => {
    const images = [
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
        { src: Partner7, alt: 'Partner 1' },
        { src: Partner8, alt: 'Partner 1' },
    ];

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 7,
            numScroll: 1,
        },
        {
            breakpoint: '768px',
            numVisible: 4,
            numScroll: 1,
        },
        {
            breakpoint: '560px',
            numVisible: 3,
            numScroll: 1,
        }
    ];

    const itemTemplate = (image) => {
        return (
            <div className="carousel-item border-0 rounded-md  mx-1">
                <img src={image.src} alt={image.alt} className="w-5" />
            </div>
        );
    };

    return (
        <div className="carousel-container mt-5 mb-5">
            <Carousel
                value={images}
                numVisible={7}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                itemTemplate={itemTemplate}
                autoplayInterval={autoplayInterval}
                circular
                showIndicators={false}
                showNavigators={false}
            />
        </div>
    );
};

export default PartnerCarousel;
