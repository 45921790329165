import React from "react"
import Layout from "../dashboardComponents/MainLayout";
export default function Environnement(){
    return(
        <Layout>
        <div className="mt-14">
            <p>env </p>
        </div>
         </Layout>
    )
}