// useAxios.js
import axios from 'axios';
import { useMemo, useState } from 'react';
import { TokenService } from './token.service';


export const useAxios = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const AxiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    });

    const loginToGetToken = async () => {
      if (isLoggingIn) {
        return;
      }
      setIsLoggingIn(true);
      try {
        return await TokenService.loginPublicToken();
      } catch (error) {
        console.error('Login failed', error);
        throw error;
      } finally {
        setIsLoggingIn(false);
      }
    };

    const addAuthHeader = async (config) => {
      let token = TokenService.getAccessToken();

      if (!token) {
        try {
          token = await loginToGetToken();
        } catch (error) {
          console.error('Error during login process:', error);
          throw error;
        }
      }
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    };

    instance.interceptors.request.use(
      async (config) => {
        let token = TokenService.getAccessToken();

        if (!token) {
          token = await loginToGetToken();
        }
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { response: { status } = {} } = error;

        if (status === 401  ) {
          try {
            const token = await TokenService.refreshAccessToken();
            if (token) {
              error.config.headers['Authorization'] = `Bearer ${token}`;
              return instance.request(error.config);
            }
          } catch (refreshError) {
            console.error('Failed to refresh token after 401, logging out...');
            TokenService.clearTokens();
            window.location.href = '/landing';
          }
        } else if (status >= 400 && status < 500) {
          console.error('Client error:', error.response.data);
        } else if (status >= 500) {
          console.error('Server error:', error.response.data);
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }, [isLoggingIn]);

  return AxiosInstance;
};
