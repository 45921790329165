import React, { useState } from 'react';
import {Modal, Button, Input, Alert, Divider, notification} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, GoogleOutlined } from '@ant-design/icons';
import { useGoogleLogin } from '@react-oauth/google';
import { ResetPasswordModal } from './ResetPasswordModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth.provider';
import ConditionsGeneralesDialog from "../../../utils/ConditionsGenerales";
import { encryptWithPublicKey } from '../../../services/rsa.service';
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA
import { useAuthService } from '../../../services/auth.service';
import { LocalStorageService } from '../../../services/localStorage.service';
import { CryptoService } from '../../../services/crypte.service';

export function LoginModal({ visible, onHide, toRoute ,onLoginSuccess }) {
    const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [emptyValue, setEmptyValue] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null); // Add captchaToken state
    const navigate = useNavigate();
    const { login, checkEmailGoogle } = useAuth();
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);

    const handleResetPassword = () => {
        setResetPasswordVisible(true);
        handleClose();
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setEmptyValue(false);
        setIsLoading(true);

        if (!email || !password) {
            setEmptyValue(true);
            setIsLoading(false);
            return;
        }

        if (!captchaToken) {
            notification.error({
                message: 'Attention',
                description: 'Veuillez compléter le reCAPTCHA pour continuer.',
                placement: 'top',
            });
            setIsLoading(false);
            return;
        }


        try {

           let encryptedPassword = encryptWithPublicKey(password);
            console.log("encryptedPassword ", encryptedPassword);
            
            const response = await login(email, encryptedPassword);
            console.log("login ", response);

            if (response.status === 'success') {
                handleClose();
                setNotFound(false);
                setIsLoading(false);
                navigate(toRoute);
                onLoginSuccess?.();
            } else {
                setNotFound(true);
                setIsLoading(false);
            }
        } catch (error) {
            setNotFound(true);
            setIsLoading(false);
        }
    };


    const googleLoginHandler = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${response.access_token}`,
                    },
                });
    
                const userInfo = await userInfoResponse.json();
                console.log(userInfo);
    
                const email = userInfo.email;
                console.log('User email:', email);
                
                let responseEmail = await checkEmailGoogle(email, userInfo.sub);
                console.log("response ", responseEmail);
                
                if (responseEmail.data.status === "success") {
                    let userResponseInfo = responseEmail.data.data;
                    console.log("userInfo ", userResponseInfo);
                    
                    LocalStorageService.saveAccessToken(userResponseInfo.access_token)
                    LocalStorageService.saveRefreshToken(userResponseInfo.refresh_token)
                    localStorage.setItem('user_email', CryptoService.encryptData(userResponseInfo.user_email.toString()));
                    localStorage.setItem('user_id', CryptoService.encryptData(userResponseInfo.user_id.toString()));
                    if (userResponseInfo.user_name && userResponseInfo.user_surname) {
                        localStorage.setItem('user_name', CryptoService.encryptData(userResponseInfo.user_name.toString()));
                        localStorage.setItem('user_surname', CryptoService.encryptData(userResponseInfo.user_surname.toString()));
                        navigate(toRoute);
                    } else {
                        LocalStorageService.saveSubGoogle(userInfo.sub.toString());
                        navigate("/public/registration");
                    }
                    handleClose();
                } else {
                    console.error('User email check was not successful:', responseEmail.data.message);
                }
    
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        },
        onError: () => {
            console.error('Google login failed');
        },
        scope: 'openid email profile',
    });
    
    
    const handleClose = () => {
        setEmail('');
        setPassword('');
        setEmptyValue(false);
        setNotFound(false);
        setIsLoading(false);
        setCaptchaToken(null); // Reset captcha token on close
        onHide();
    };

    return (
        <>
            <Modal
                title={<h2 className="text-xl text-center font-semibold font-inter">Connectez-vous</h2>}
                visible={visible}
                onCancel={handleClose}
                footer={null}
                centered
                width={450}
            >
                {emptyValue && (
                    <Alert
                        message="Veuillez remplir tous les champs"
                        type="error"
                        showIcon
                        className="mb-2"
                    />
                )}

                {notFound && (
                    <Alert
                        message="Vérifiez les données et réessayez"
                        type="error"
                        showIcon
                        className="mb-2"
                    />
                )}

                <div className="mb-3 w-full">
                    <label htmlFor="email" className="block text-sm font-medium mb-1 text-left">
                        E-mail
                    </label>
                    <Input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="you@linkyways.com"
                        size="large"
                    />
                </div>

                <div className="mb-3 w-full">
                    <label htmlFor="password" className="block text-sm font-medium mb-1 text-left">
                        Mot de passe
                    </label>
                    <Input.Password
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="********"
                        size="large"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <div className="text-right mt-2">
                        <span
                            className="text-sm text-blue-500 underline cursor-pointer"
                            onClick={handleResetPassword}
                        >
                            Mot de passe oublié?
                        </span>
                    </div>
                </div>

                {/* CAPTCHA Element */}
                <div className=" flex justify-center">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={handleCaptchaChange}
                        className="mb-3"
                    />
                </div>


                <Button
                    type="primary"
                    block
                    size="large"
                    onClick={handleLogin}
                    loading={isLoading}
                    className="mb-4"
                >
                    {isLoading ? 'Chargement...' : 'Se connecter'}
                </Button>

                <p className="text-xs text-gray-500 mb-2">
                    En cliquant sur "Se connecter" ou "Continuer", vous acceptez les{' '}
                    <button
                        onClick={() => setOpenConditionsDialog(true)}
                        className="text-blue-500 cursor-pointer underline bg-transparent border-none p-0"
                        type="button"
                    >
                        CGU
                    </button>{' '}
                    de Linkyways.
                </p>

                <Divider>OU</Divider>

                <Button
                    icon={<GoogleOutlined />}
                    block
                    size="large"
                    onClick={() => googleLoginHandler()}
                >
                    Continuer avec Gmail
                </Button>

                <p className="text-sm text-[#434769] cursor-pointer text-center mt-3">
                    Pas encore de compte ?{' '}
                    <button
                        onClick={() => window.location.href = '/public/registration'}
                        className="text-sm text-blue-500 underline">
                        S'inscrire
                    </button>
                    {' '}gratuitement.
                </p>
            </Modal>

            <ResetPasswordModal visible={resetPasswordVisible} onHide={() => setResetPasswordVisible(false)} />
            <ConditionsGeneralesDialog open={openConditionsDialog} onClose={() => setOpenConditionsDialog(false)} />
        </>
    );
}

export default LoginModal;




// import React, { useState } from 'react';
// import { Modal, Button, Input, Alert, Divider } from 'antd';
// import { EyeInvisibleOutlined, EyeTwoTone, GoogleOutlined } from '@ant-design/icons';
// import { useGoogleLogin } from '@react-oauth/google';
// import { ResetPasswordModal } from './ResetPasswordModal';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../../services/auth.provider';
// import ConditionsGeneralesDialog from "../../../utils/ConditionsGenerales";
// import { encryptWithPublicKey } from '../../../services/rsa.service';
//
//
// export function LoginModal({ visible, onHide, toRoute }) {
//     const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [notFound, setNotFound] = useState(false);
//     const [emptyValue, setEmptyValue] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const navigate = useNavigate();
//     const { login, googleLogin } = useAuth();
//     const [openConditionsDialog, setOpenConditionsDialog] = useState(false);
//
//     const handleResetPassword = () => {
//         setResetPasswordVisible(true);
//         handleClose();
//     };
//
//     const handleLogin = async (e) => {
//         setEmptyValue(false);
//         e.preventDefault();
//         setIsLoading(true);
//
//         if (!email || !password) {
//             setEmptyValue(true);
//             setIsLoading(false);
//             return;
//         }
//
//         try {
//            // let encryptedPassword = encryptWithPublicKey(password);
//          //   console.log("encryptedPassword ", encryptedPassword);
//
//             const response = await login(email, password);
//             console.log("login ", response);
//
//             if (response.status === 'success') {
//                 handleClose();
//                 setNotFound(false);
//                 setIsLoading(false);
//                 console.log("toRoute ", toRoute);
//
//                 navigate(toRoute);
//             } else {
//                 setNotFound(true);
//                 setIsLoading(false);
//             }
//         } catch (error) {
//             setNotFound(true);
//             setIsLoading(false);
//         }
//     };
//
//     const googleLoginHandler = useGoogleLogin({
//         onSuccess: async (response) => {
//             const token = response.credential;
//             try {
//                 await googleLogin(token);
//             } catch (error) {
//                 console.error('Google login failed:', error);
//             }
//         },
//         onError: () => {
//             console.error('Google login failed');
//         }
//     });
//
//     const handleClose = () => {
//         setEmail('');
//         setPassword('');
//         setEmptyValue(false);
//         setNotFound(false);
//         setIsLoading(false);
//         onHide();
//     };
//
//     return (
//         <>
//             <Modal
//                 title={<h2 className="text-xl text-center font-semibold font-inter">Connectez-vous</h2>}
//                 visible={visible}
//                 onCancel={handleClose}
//                 footer={null}
//                 centered
//                 width={450}
//             >
//                 {emptyValue && (
//                     <Alert
//                         message="Veuillez remplir tous les champs"
//                         type="error"
//                         showIcon
//                         className="mb-2"
//                     />
//                 )}
//
//                 {notFound && (
//                     <Alert
//                         message="Vérifiez les données et réessayez"
//                         type="error"
//                         showIcon
//                         className="mb-2"
//                     />
//                 )}
//
//                 <div className="mb-3 w-full">
//                     <label htmlFor="email" className="block text-sm font-medium mb-1 text-left">
//                         E-mail
//                     </label>
//                     <Input
//                         type="email"
//                         id="email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         placeholder="you@linkyways.com"
//                         size="large"
//                     />
//                 </div>
//
//                 <div className="mb-3 w-full">
//                     <label htmlFor="password" className="block text-sm font-medium mb-1 text-left">
//                         Mot de passe
//                     </label>
//                     <Input.Password
//                         id="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         placeholder="********"
//                         size="large"
//                         iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
//                     />
//                     <div className="text-right mt-2">
//                         <span
//                             className="text-sm text-blue-500 underline cursor-pointer"
//                             onClick={handleResetPassword}
//                         >
//                             Mot de passe oublié?
//                         </span>
//                     </div>
//                 </div>
//
//                 <Button
//                     type="primary"
//                     block
//                     size="large"
//                     onClick={handleLogin}
//                     loading={isLoading}
//                     className="mb-4"
//                 >
//                     {isLoading ? 'Chargement...' : 'Se connecter'}
//                 </Button>
//
//                 <p className="text-xs text-gray-500 mb-2">
//                     En cliquant sur "Se connecter" ou "Continuer", vous acceptez les{' '}
//                     <button
//                         onClick={() => setOpenConditionsDialog(true)}
//                         className="text-blue-500 cursor-pointer underline bg-transparent border-none p-0"
//                         type="button"
//                     >
//                         CGU
//                     </button>{' '}
//                     de Linkyways.
//                 </p>
//
//                 <Divider>OU</Divider>
//
//                 <Button
//                     icon={<GoogleOutlined />}
//                     block
//                     size="large"
//                     onClick={() => googleLoginHandler()}
//                 >
//                     Continuer avec Gmail
//                 </Button>
//
//                 <p className="text-sm text-[#434769] cursor-pointer text-center mt-3">
//                     Pas encore de compte ?{' '}
//                     <button
//                         onClick={() => window.location.href = '/public/registration'}
//                         className="text-sm text-blue-500 underline">
//                         S'inscrire
//                     </button>
//                     {' '}gratuitement.
//                 </p>
//             </Modal>
//
//             <ResetPasswordModal visible={resetPasswordVisible} onHide={() => setResetPasswordVisible(false)} />
//             <ConditionsGeneralesDialog open={openConditionsDialog} onClose={() => setOpenConditionsDialog(false)} />
//         </>
//     );
// }
//
// export default LoginModal;
