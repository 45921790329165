import React from "react";

export default function FormationLinearChart({
                                                 effectif_generaux,
                                                 effectif_professionnels,
                                                 effectif_technologiques,
                                             }) {
    // Convert the values from string to number
    const general = parseFloat(effectif_generaux);
    const professionnels = parseFloat(effectif_professionnels);
    const technologiques = parseFloat(effectif_technologiques);

    // Calculate the total and percentages
    const total = general + professionnels + technologiques;

    // Check if there are valid values to display
    // if (isNaN(total) || total === 0) {
    //     return (
    //         <div>
    //             <p className="font-poppins text-[16px] font-semibold text-[#141943]">
    //                 Répartition par filière d’origine
    //             </p>
    //             <p className="font-poppins text-[10px] font-semibold text-blue-500">
    //                 Données non disponibles
    //             </p>
    //         </div>
    //     );
    // }

    const generalPercent = ((general / total) * 100).toFixed(1);
    const professionnelPercent = ((professionnels / total) * 100).toFixed(1);
    const technoPercent = ((technologiques / total) * 100).toFixed(1);

    return (
        <>
            <p className="font-poppins text-[16px] font-semibold text-[#141943]">
                Répartition par filière d’origine
            </p>
            <div className="flex items-center -mt-1">
                <div className="flex-1">
                    {/* Bac général */}
                    <p className="text-[#434769] text-[12px] font-inter">Bac général</p>
                    <div className="rounded-md h-7 w-full ">
                        <div
                            className="w-full mt-1.5 bg-[#E5EAF0] relative h-7"  // Ensures that the parent has height
                            role="progressbar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            <div
                                className="absolute top-0 left-0 bg-[#434769] h-full"
                                style={{width: `${generalPercent}%`}} // Full height and specified width
                            />
                            <span className="absolute right-0 mr-2 text-xs text-blue-500 font-bold mt-1.5">
                                {generalPercent}%
                            </span>
                        </div>
                    </div>

                    {/* Bac technologique */}
                    <p className="text-[#434769] text-[12px] font-inter my-2">Bac technologique</p>
                    <div className="rounded-md h-7 w-full ">
                        <div
                            className="w-full mt-1.5 bg-[#E5EAF0] relative h-7"
                            role="progressbar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            <div
                                className="absolute top-0 left-0 bg-[#434769] h-full"
                                style={{width: `${technoPercent}%`}}
                            />
                            <span className="absolute right-0 mr-2 text-xs text-blue-500 font-bold mt-1.5">
            {technoPercent}%
          </span>
                        </div>
                    </div>

                    {/* Bac professionnel */}
                    <p className="text-[#434769] text-[12px] font-inter my-2">Bac professionnel</p>
                    <div className="rounded-md h-7 w-full ">
                        <div
                            className="w-full mt-1.5 bg-[#E5EAF0] relative h-7"
                            role="progressbar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            <div
                                className="absolute top-0 left-0 bg-[#434769] h-full"
                                style={{width: `${professionnelPercent}%`}}
                            />
                            <span className="absolute right-0 mr-2 text-xs text-blue-500 font-bold mt-1.5">
                                {professionnelPercent}%
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}
