// import React from 'react';
// import Modal from 'react-modal';
// import RatingComponent from '../RatingComponent';
// import trashIcon from "../../images/modal/trashicon.svg"; // Import your custom RatingComponent
//
// Modal.setAppElement('#root'); // Set the app element for accessibility
//
// const RatingModal = ({ visible, item, rating, onClose, onRatingChange, onDelete }) => (
//     <Modal
//         isOpen={visible}
//         onRequestClose={onClose}
//         style={{
//             overlay: {
//                 backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                 zIndex: 1000,
//             },
//             content: {
//                 top: '50%',
//                 left: '50%',
//                 right: 'auto',
//                 bottom: 'auto',
//                 transform: 'translate(-50%, -50%)',
//                 width: '90%',
//                 maxWidth: '400px',
//                 padding: '1rem',
//                 borderRadius: '0.5rem',
//                 backgroundColor: 'white',
//                 textAlign: 'center',
//                 boxSizing: 'border-box',
//                 zIndex: 1001,
//             },
//         }}
//     >
//         {/* Close Button */}
//         <button
//             type="button"
//             onClick={onClose}
//             className="absolute top-2 right-2 text-gray-600 text-2xl"
//             style={{ background: 'none', border: 'none', cursor: 'pointer' }}
//         >
//             &times;
//         </button>
//
//         {/* Content */}
//         <h2 className="text-xl font-semibold mb-2">{item ? item.label : 'No Item Selected'}</h2>
//         <div className="p-4">
//             <p className="text-sm mb-4">Quel est votre niveau d'intérêt pour ce sujet ?</p>
//             <div className="flex justify-center mb-4">
//                 <RatingComponent
//                     ratingValue={rating}
//                     maxRating={3}
//                     starColor="text-yellow-400"
//                     inactiveStarColor="text-gray-300"
//                     size="w-8 h-8"
//                     onRatingChange={onRatingChange}
//                 />
//             </div>
//         </div>
//
//         <div className="flex justify-end  ">
//         <img
//             src={trashIcon}
//             alt="Delete"
//             className="  ml-1 cursor-pointer mr-2 block md:hidden border-1 border-blue-500  px-4 py-2 rounded-md "
//             onClick={onDelete}
//             aria-label="Delete item"
//         />
//         </div>
//     </Modal>
// );
//
// export default RatingModal;
//
import React from 'react';
import { Modal, Button } from 'antd';
import RatingComponent from '../RatingComponent';
import trashIcon from '../../images/modal/trashicon.svg';

const RatingModal = ({ visible, item, rating, onClose, onRatingChange, onDelete }) => (
    <Modal
        visible={visible}
        onCancel={onClose}
        className=" p-2   justify-center"
        footer={ <div className="flex justify-end">
            <img
                src={trashIcon}
                alt="Delete"
                className="ml-1 cursor-pointer mr-2 block lg:hidden border-1 border-blue-500 px-4 py-2 rounded-md"
                onClick={onDelete}
                aria-label="Delete item"
            />
        </div>}
        centered
    >
        {/* Content */}
        <div>
            <div className="w-full flex justify-center">
                <h2 className="text-xl font-semibold mb-2">
                    {item ? item.label : 'No Item Selected'}
                </h2>
            </div>
            <p className="w-full flex justify-center text-sm mb-4">Quel est votre niveau d'intérêt pour ce sujet ?</p>
            <div className="flex justify-center mb-4">
                <RatingComponent
                    ratingValue={rating}
                    maxRating={3}
                    starColor="text-yellow-400"
                    inactiveStarColor="text-gray-300"
                    //size="w-8 h-8"
                    onRatingChange={onRatingChange}
                />
            </div>
        </div>


    </Modal>
);

export default RatingModal;
