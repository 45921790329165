import React, { useEffect, useState } from 'react';
import { useUserService } from '../../services/userforms.service';
import SingleCard from './cards/SingleCard';
import CircularProgress from "@mui/material/CircularProgress";

export default function Filiere({ selectedData, setSelectedData }) {
    const [responseData, setResponseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getBacType } = useUserService();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await getBacType();
            if (response.data.status === 'success') {
                const fetchedTypes = response.data.data.map((item) => ({
                    id: item.bac_type_id,
                    value: item.bac_type
                }));
                setResponseData(fetchedTypes);
            } else {
                setError(response.data.message || 'An error occurred');
            }
        } catch (err) {
            setError(err.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setSelectedData(parseInt(event.target.id));
        // console.log("Filiere value:", event.target);
    };


    if (loading) {
        return(
            <div className="flex justify-center mt-5">
                <CircularProgress />
            </div>
        )
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (

        <div className="mt-5">
            <p className="font-poppins text-2xl mb-1">Quelle est votre filière ?</p>
            <p className="font-inter text-[16px] font-normal leading-[25.6px] text-left mt-2 mb-2">Découvrez les possibilités qui s’offrent à vous.</p>
            <div className="grid grid-vol-1 md:grid-cols-2 gap-1  py-5 px-2 ">
                {responseData.map((option) => (
                    <SingleCard
                        id={option.id}
                        key={option.id}
                        value={option.value}
                        label={option.value}
                        checked={selectedData == option.id}
                        onChange={handleChange}
                    />
                ))}
            </div>
        </div>
    );
}