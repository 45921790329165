import { useAxios } from './caller.service';

export const useMetierService = () => {
    const AxiosInstance = useAxios();

    let get35Professions = () => {
        return AxiosInstance.get('/35_professions/');
    }

    // let searchProfessions = (
    //     studyLevels = undefined,
    //     businessSectors = undefined,
    //     seniorPosition = undefined,
    //     salaryRangeId = undefined,
    //     keywords = undefined,
    //     professionId = undefined,
    //     userId = undefined,
    //     page=1,
    //
    // ) => {
    //     let queryParams = {};
    //
    //     if (studyLevels) queryParams.study_levels = studyLevels;
    //     if (businessSectors) queryParams.business_sectors = businessSectors;
    //     if (seniorPosition !== undefined) queryParams.senior_position = seniorPosition;
    //     if (salaryRangeId) queryParams.salary_range_id = salaryRangeId;
    //     if (professionId) queryParams.profession_id = professionId;
    //     if (keywords) queryParams.keywords = keywords;
    //     if (userId) queryParams.user_id = userId;
    //     if (page) queryParams.page = page;
    //
    //     // console.log(queryParams, "queryParams");
    //
    //     return AxiosInstance.get('/search_professions', { params: queryParams });
    // };


    let searchProfessions = (
        studyLevels = undefined,
        businessSectors = undefined,
        seniorPosition = undefined,
        salaryRangeId = undefined,
        keywords = undefined,
        professionId = undefined,
        userId = undefined,
        page = undefined
    ) => {
        let queryParams = {};

        // Construct query parameters based on inputs
        if (studyLevels) queryParams.study_levels = studyLevels;
        if (businessSectors) queryParams.business_sectors = businessSectors;
        if (seniorPosition !== undefined) queryParams.senior_position = seniorPosition;
        if (salaryRangeId) queryParams.salary_range_id = salaryRangeId;
        if (professionId) queryParams.profession_id = professionId;
        if (keywords) queryParams.keywords = keywords;
        if (userId) queryParams.user_id = userId;
        if (page) queryParams.page_number = page;

        return AxiosInstance.get('/search_professions', { params: queryParams })
            .then(response => {
                if (response.data.status === "success") {
                    const { total_count, total_pages, results } = response.data.data;
                    return {
                        success: true,
                        message: response.data.message,
                        data: {
                            total_count,
                            total_pages,
                            results
                        }
                    };
                } else {
                    return {
                        success: false,
                        message: response.data.message || "Unexpected response structure.",
                        data: []
                    };
                }
            })
            .catch(error => {
                console.error("Error fetching professions:", error);
                return {
                    success: false,
                    message: "Error fetching professions.",
                    data: []
                };
            });
    };

    const get35aProfessionsDescription = async (professionId, userId) => {
        if (!professionId) {
            return Promise.reject('Profession ID is required');
        }

        try {
            // Build query parameters
            const params = { profession_id: professionId };
            if (userId) {
                params.user_id = userId; // Include user_id if provided
            }

            const response = await AxiosInstance.get('/35a_professions_description/', {
                params: params // Pass query parameters
            });

            // Check if the response is successful
            if (response.data.status === 'success') {
                return response.data; // Return the full response data
            } else {
                throw new Error(response.data.message || 'Failed to fetch profession details');
            }
        } catch (error) {
            console.error("Error fetching profession description:", error);
            throw new Error(error.response ? error.response.data.message : error.message);
        }
    };

    const getProfessionsPublic = () => {
        // return AxiosInstance.get('/getfirst_professions_public/')
        //     .then(response => {
        //         if (response.data.status === "success") {
        //             return {
        //                 success: true,
        //                 message: response.data.message,
        //                 data: response.data.data
        //             };
        //         } else {
        //             return {
        //                 success: false,
        //                 message: response.data.message || "Unexpected response structure.",
        //                 data: []
        //             };
        //         }
        //     })
        //     .catch(error => {
        //         console.error("Error fetching public professions:", error);
        //         return {
        //             success: false,
        //             message: "Error fetching public professions.",
        //             data: []
        //         };
        //     });
        return AxiosInstance.get('/getfirst_professions_public/')
            .then(response => {
                if (response.data.status === "success") {
                    const { results } = response.data.data;
                    return {
                        success: true,
                        message: response.data.message,
                        data: results
                    };
                } else {
                    return {
                        success: false,
                        message: response.data.message || "Unexpected response structure.",
                        data: []
                    };
                }
            })
            .catch(error => {
                console.error("Error fetching first 16 public items:", error);
                return {
                    success: false,
                    message: "Error fetching first 16 public items.",
                    data: []
                };
            });
    };

    return {
        get35Professions,
        searchProfessions,
        get35aProfessionsDescription,
        getProfessionsPublic
    }
}
