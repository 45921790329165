import React from 'react';
import { Popconfirm, Button } from 'antd';
import { DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {CryptoService} from "../services/crypte.service";
import {useNavigate} from "react-router-dom";

const SavedMetierCard = ({
    imgSrc,
    smtitle,
    title,
    metiersId,
    chips,
    frompriceDebutant,
    topriceDebutant,
    frompriceExp,
    topriceExp,
    onDelete,
    navigateTo
}) => {
    const navigate = useNavigate();
    const handleClick = () => {
        let metiersName = CryptoService.nameUrlFormatVF(title)
        navigate(`/metiers/${metiersName}/${metiersId}`)
    };
    return (
        <div className="bg-white border-1 hover:border-blue-500 rounded-lg shadow-md p-2 relative">
            <div className="flex justify-between items-start">
                <div className="flex items-center space-x-2 cursor-pointer" onClick={handleClick}>
                    <img
                        src={imgSrc}
                        alt="logo"
                        className=" object-cover opacity-100 max-w-10"
                    />
                    <div>
                        <p className="font-inter text-[14px] font-medium leading-[17.5px] text-[#434769] text-left  max-h-[20px]">
                            {smtitle || ''}
                        </p>
                    </div>

                </div>
                <div className="relative flex justify-end">
                    <Popconfirm
                        title="Etes-vous sûr de vouloir supprimer cet metier ?"
                        onConfirm={onDelete}
                        okText={<CheckOutlined />}
                        cancelText={<CloseOutlined />}
                        placement="topRight"
                    >
                        <Button
                            shape="circle"
                            icon={<DeleteOutlined />}
                            size="small"
                            type="text"
                            className="w-[18px] h-[18px] p-0 opacity-100"
                        />
                    </Popconfirm>
                </div>
            </div>

            <div onClick={navigateTo} className="cursor-pointer">
            <h3 className="font-inter text-[#141943] mt-4 min-h-[35px] cursor-pointer" onClick={handleClick}>{title || ''}</h3>
            <hr className="my-[1px] bg-[#D0DBE7] m-2 mt-3 mb-3" />
            <div className="flex flex-wrap gap-2 cursor-pointer" onClick={handleClick}>
                {chips && chips.map((chip, idx) => (
                    <span key={idx} className="bg-gray-200 text-gray-700 text-xs font-semibold px-2 py-1 rounded">
                        {chip}
                    </span>
                ))}
            </div>
            <div className="flex justify-between items-center mt-2">
                <p className="font-inter text-[12px] font-normal leading-[15px] text-left text-[#434769]">Débutant :</p>
                <p className="font-inter text-[12px] font-medium leading-[15px] text-center text-[#434769]">{frompriceDebutant || ''}€ - {topriceDebutant || ''}€</p>
            </div>
            <div className="flex justify-between items-center mt-2">
                <p className="font-inter text-[12px] font-normal leading-[15px] text-left text-[#434769]">Expérimenté :</p>
                <p className="font-inter text-[12px] font-medium leading-[15px] text-center text-[#434769]">{frompriceExp || ''}€ - {topriceExp || ''}€</p>
            </div>
            </div>
        </div>
    );
};

export default SavedMetierCard;
